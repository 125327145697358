import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import usePutDataServices from "../../hooks/usePutDataServices";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import Loader from "../common/Loader";
import { useNavigate } from "react-router";

import AddAssetModal from "../../popupModals/AddAssetModal"
import { Link } from "react-router-dom";

const AssetBox = () => {
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const [allAssets, setAllAssets] = useState([]);
  const [loader, setLoader] = useState(true);
  const [disableToggle, setDisableToggle] = useState();
  const navigate = useNavigate();

  const [addAssetModal, setAddAssetModal] = useState({
    visibility: false,
    type: "",
    id: "",
  });

  const handleAddEditData = (type, id) => {
    setAddAssetModal({
      ...addAssetModal,
      visibility: true,
      type,
      id,
    });
  };

  const handlePublish = (id) =>{
    UpdateApiServices.publishAsset(id)
    .then((res)=>{
      SuccessToast("Asset Published Successfully");
      fetchData(GetApiServices.getAllAssets(), setAllAssets, setLoader)
    })
    .catch((err)=>{
      ErrorToast('Something Went Wrong while publishing');
    });
  };

  const handleStatus =(data)=>{
    setDisableToggle(true)
    let status = data.isActive ? false : true ;

    UpdateApiServices.updateAssetStatus(data.id,status)
    .then((res)=>{
      SuccessToast(`Status changed successfully`)
      fetchData(GetApiServices.getAllAssets(), setAllAssets, setLoader)
    })
    .catch((err)=>{
      ErrorToast('Something Went Wrong');
    })
    .finally(()=>setDisableToggle(false))
  }

 useEffect(() => {
    fetchData(GetApiServices.getAllAssets(), setAllAssets, setLoader)
  }, [addAssetModal]);



  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">Assets</h4>
          </div>
          
          <div className="col-auto pe-1">
            <button className="btn btn-outline-primary add-button col-auto me-2" onClick={() => navigate("/assets/user-asset")}>
                User Assets
            </button>
            <button
              className="btn btn-primary add-button col-auto"
              onClick={() => handleAddEditData("Add_Data")}
               > 
              Add Asset
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 col-md-12 table-responsive fixed-table"
            style={{ overflowX: "auto" }}
          >
            <table class="table table-fit  align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className=" table-header-font align-middle">
                  {/* <th scope="col">Sr. no</th> */}
                  <th scope="col" className="">Name</th>
                  <th scope="col" className="col-2">Overview</th>
                  <th scope="col" className="col-2">Description</th>
                  <th scope="col" className="text-center">Asset Icon</th>
                  <th scope="col" className="text-center">Token Amount</th>
                  <th scope="col" className="text-center">Categories</th>                  
                  <th scope="col" className="col-2 text-center">Status</th>
                  <th scope="col" className="col-2 text-center">Publish</th>
                  <th scope="col" className="col-2 text-center"></th>
                </tr>
              </thead>
              <tbody className="">
              {loader ? (
                  <tr className=" my-5 text-center">
                    <th colSpan="9" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) : allAssets.length ? (
                  allAssets.map((asset, index) => (
                  <tr key={index}>                    
                    <td className="col">{asset.name}</td>
                    <td className="col">{asset.overview}</td>
                    <td className="col" dangerouslySetInnerHTML={{ __html: asset.description }}></td>
                    <td className="col">
                        <Link to={asset.imageUrl} target="_blank">
                        <img src={asset.imageUrl} width="70" height="70" />
                        </Link>
                    </td>                    
                    <td className="col text-center">{asset.tokenAmount}</td>
                    <td className="col">{asset.category.name}</td>
                    <td className="text-center">
                        <div className="form-switch">
                        <input className="form-check-input custom-control-input" type="checkbox" role="switch" disabled={!asset.isPublish || disableToggle}
                        checked={asset.isActive} onChange={()=>handleStatus(asset)} defaultChecked/>
                        </div>
                    </td>
                    <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={asset.isPublish}
                            checked={asset.isPublish} onClick={()=>handlePublish(asset.id)}
                            />
                          </div>
                        </td>
                    <td className="text-center col-2">
                      {/* <div className="row justify-content-center">
                        <div className="col-auto px-2">

                           {asset.isPublish ? (
                            <button className="btn btn-primary edit-button"  onClick={()=>handleAddEditData("View_Data",asset.id)}>
                                View
                            </button>
                            ) : 
                            (
                              <button className="btn btn-primary edit-button" onClick={()=>handleAddEditData("Edit_Data",asset.id)}>
                              Edit
                            </button>
                            ) }
                        </div>                                                
                        <div className="col-auto px-2">
                            <button
                                className="btn btn-outline-secondary delete-button"
                                disabled={asset.isPublish}
                                onClick={() =>
                                handlePublish(asset.id)} >
                                 Publish
                            </button>
                        </div>
                      </div> */}

                      <div className="btn-group">
                          <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul className="dropdown-menu py-0">
                           {asset.isPublish ?
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${asset.id}`) } >
                              <i className="fa-solid fa-eye"></i> View
                              </li>
                            </div>
                            :
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${asset.id}`) } >
                              <i className="fa-solid fa-pen-to-square"></i> Edit
                              </li>
                            </div>}

                          </ul>
                        </div>
                    </td>
                  </tr>
                ))
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
        {addAssetModal.visibility ? (
          <AddAssetModal
          addAssetModal={addAssetModal}
          setAddAssetModal={setAddAssetModal}
          assetId={addAssetModal.id}
          actionType={addAssetModal.type}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AssetBox;
