import { ErrorToast } from "../utilities/notification";

// For validating image dimensions (height and width)
export const validateImageDimensions = async (file, setterFunction, state) => {
  if (file) {
    let img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    let imgWidth = img.width;
    let imgHeight = img.height;
    if (imgWidth <= 512 && imgHeight <= 512) {
      setterFunction({
        ...state,
        imageUrl: file?.name,
        previewImage: file && URL.createObjectURL(file),
      });
    } else {
      setterFunction({
        ...state,
        imageUrl: "",
        previewImage: "",
      });
      ErrorToast("Image dimension not matched !!!");
    }
  }
};


// Purpose : Fetching Data using getApis for particular endpoint
export async function fetchData(GET_API, setterFunction, loaderFunction) {
  try {
    const response = await GET_API;
    setterFunction(response.data);
  } catch (error) {
    ErrorToast(error);
  } finally {
    loaderFunction(false);
  }
}

export const convertHtmlToText = (htmlContent) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlContent, 'text/html');
  const textContent = parsedHtml.body.textContent || '';
  return textContent;
}

//Purpose : For token validation
export const validateToken = (amount, setState, state, updateFunction) => {
  let tokenValue = amount;
  if (tokenValue.length < 6) {
    if (/^\d*$/.test(tokenValue)) {
      const parsedValue = parseInt(tokenValue, 10);
      const newTokenAmount = !isNaN(parsedValue) ? Math.abs(parsedValue) : '';
      setState({ ...state, tokenAmount: newTokenAmount });
      updateFunction("tokenAmount", newTokenAmount);
    }
  }
}
