import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../popupModals/DeleteModal";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import BadgeModal from "../../popupModals/BadgeModal";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";
import { Link } from "react-router-dom";

const Badges = () => {
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const UpdateApiServices = usePutDataServices();
    const [badges, setAllBadges] = useState([]);
    const [loader, setLoader] = useState(true);
    const [disableToggle, setDisableToggle] = useState();
    const [addBadgeModal, setAddBadgeModal] = useState({
      visibility: false,
      type: "",
      id:""
    });
    const [deleteModal, setDeleteModal] = useState({
      visibility: false,
      deleteItem: "",
      id:""
    });
    const handleAddEditData = (type,id) =>{
        setAddBadgeModal({
        ...addBadgeModal,
        visibility: true,
        type,
        id
      });
    }

    const handlePublish = (id) =>{
      UpdateApiServices.publishBadges(id)
      .then((res)=>{
        SuccessToast("Badge Published Successfully");
        fetchData(GetApiServices.getAllBadges(), setAllBadges, setLoader)
      })
      .catch((err)=>{
        ErrorToast('Something Went Wrong while publishing');
      });
    };

    const handleStatus =(data)=>{
      setDisableToggle(true)
      let status = data.isActive ? false : true ;
  
      UpdateApiServices.updateBadgesStatus(data.id,status)
      .then((res)=>{
        SuccessToast(`Status changed successfully`)
        fetchData(GetApiServices.getAllBadges(), setAllBadges, setLoader)
      })
      .catch((err)=>{
        ErrorToast('Something Went Wrong');
      })
      .finally(()=>setDisableToggle(false))
    }

    useEffect(() => {
      fetchData(GetApiServices.getAllBadges(), setAllBadges, setLoader)
    }, [addBadgeModal]);


    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col ps-1">
              <h4 className="text-primary fw-bold">Badges</h4>
            </div>
            <div className="col-auto pe-1">              
              <button
                className="btn btn-primary add-button col-auto"
                onClick={() => handleAddEditData("Add_Data")}
              >
                Add Badge
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table class="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col" className="col-3">Name</th>
                    <th scope="col" className="text-center col-2">Badges Graphic</th>
                    <th scope="col" className="text-center col-4">Category</th> 
                    <th scope="col" className="text-center col">Status</th>
                    <th scope="col" className="col text-center">Publish</th>
                    <th scope="col" className="col text-center"></th>
                  </tr>
                </thead>
                <tbody className="">
                  
                {loader ? (
                  <tr className=" my-5 text-center">
                    <th colSpan="9" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) : 
                badges.length ? (
                 badges.map((item)=>(
                    <tr>                    
                        <td scope="col" className="">{item.title}</td>
                        <td className="text-center">
                            <Link to={item.imageUrl} target="_blank">
                                <img src={item.imageUrl} width="70" height="70" />
                            </Link>
                        </td>
                        <td className="col text-center">{item.category.name}</td>
                        <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={!item.isPublish || disableToggle}
                        checked={item.isActive} onChange={()=>handleStatus(item)}/>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch"  disabled={item.isPublish}
                                checked={item.isPublish} onClick={()=>handlePublish(item.id)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                        <div className="btn-group">
                          <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul className="dropdown-menu badges-dropdown py-0">
                           {item.isPublish ?
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${item.id}`) } >
                              <i className="fa-solid fa-eye"></i> View
                              </li>
                            </div>
                            :
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${item.id}`) } >
                              <i className="fa-solid fa-pen-to-square"></i> Edit
                              </li>
                            </div>}
                          </ul>
                        </div>
                        </td>
                      </tr>
                  ))
                ) : 
                <tr className="my-5 text-center">
                    <th colSpan="5" className="py-5">
                      <h4 className="text-muted">No Data Available</h4>
                    </th>
                </tr>
                 }
                   
                </tbody>
              </table>
            </div>
          </div>
          {addBadgeModal.visibility ? (
            <BadgeModal
              type={addBadgeModal.type}
              id={addBadgeModal.id}
              addBadgeModal={addBadgeModal.visibility}
              setAddBadgeModal={setAddBadgeModal}
            />
          ) : (
            ""
          )}
          {deleteModal.visibility ? (
            <ConfirmationModal
              deleteItem={deleteModal.deleteItem}
              deleteModal={deleteModal.visibility}
              setDeleteModal={setDeleteModal}
              setLoader={setLoader}
              id={deleteModal.id}
              deleteDataApi={() => DeleteApiServices.deleteOnboardingScreen(deleteModal.id)}
              // setNewData={setUserList}
              // fetchNewDataApi={() => GetApiServices.getAlluserlist()}
            />
          ) : (
            ""
          )}
        </div>
        </>
      );
}

export default Badges