import { svcconfig } from '../utilities/api-confige';
import useAxios from './useAxios';

const usePutDataServices = () => {
    const axios = useAxios();

    const updateWelcomeScreens = async (data, id) => {
      const apiURL = `/web-admin/welcomeScreen/${id}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }

    const updateOnboardingScreens = async (data, id) => {
      const apiURL = `/web-admin/onboardingScreen/${id}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }

    const updateCategory = async (data, id) => {
      const apiURL = `/web-admin/categories/${id}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }
    
    const updateCategoryStatus = async (id,status) => {
      const apiURL = `/web-admin/categories/status/${id}/?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }
    
    const publishCategory = async (id) => {
      const apiURL = `/web-admin/categories/publish/${id}?publish=true`
      return await axios.put(apiURL,{header : svcconfig()})
    }

    const updateOnboardingQuestions = async (id,data) => {
      const apiURL = `/web-admin/onboardingQuestion/${id}`
      return await axios.put(apiURL,data ,{header : svcconfig()})
    }

    const updateOnboardingQuestionsStatus = async (id,status) => {
      const apiURL = `/web-admin/onboardingQuestion/status/${id}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const publishOnboardingQuestions = async (id) => {
      const apiURL = `/web-admin/onboardingQuestion/publish/${id}?publish=true`
      return await axios.put(apiURL,{header : svcconfig()})
    }

    const updateCommuinityProfile = async (id,data) => {
      const apiURL = `/web-admin/communities/${id}`
      return await axios.put(apiURL,data ,{header : svcconfig()})
    }

    const updateCommuinityProfileStatus = async (id, status) => {
      const apiURL = `/web-admin/communities/status/${id}?status=${status}`
      return await axios.put(apiURL,{header : svcconfig()})
    }

    const publishCommuinityProfile = async (id) => {
      const apiURL = `/web-admin/communities/publish/${id}?publish=true`
      return await axios.put(apiURL,{header : svcconfig()})
    }
    
    const updateToken = async (id,data) => {
      const apiURL = `/web-admin/token-activities/${id}`
      return await axios.put(apiURL,data ,{header : svcconfig()})
    }

    const updateTokenStatus = async (id,status) => {
      const apiURL = `/web-admin/token-activities/status/${id}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const updateSurvey = async (id,data) => {
      const apiURL = `/web-admin/survey/${id}`
      return await axios.put(apiURL,data ,{header : svcconfig()})
    }

    const updateSurveyStatus = async (id,status) => {
      const apiURL = `/web-admin/survey/status/${id}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const publishSurvey = async (id) => {
      const apiURL = `/web-admin/survey/publish/${id}?publish=true`
      return await axios.put(apiURL,{header : svcconfig()})
    }

    const updateSurveyQuestionStatus = async (id,status) => {
      const apiURL = `/web-admin/survey/question/status/${id}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const updateSurveyQuestion = async (questionId, data) => {
      const apiURL = `/web-admin/survey/question/${questionId}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }

    const updateAsset = async (data, assetId) => {
      const apiURL = `/web-admin/assets/${assetId}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }
    
    const updateAssetStatus = async (assetId,status) => {
      const apiURL = `/web-admin/assets/status/${assetId}/?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }
    
    const publishAsset = async (assetId) => {
      const apiURL = `/web-admin/assets/publish/${assetId}?publish=true`
      return await axios.put(apiURL, {header : svcconfig()})
    }
    const updateUserStatus = async (id,status)=>{
      const apiURL=`/web-admin/users/status/${id}?status=${status}`
      return await axios.put(apiURL,{header : svcconfig()})
    }

    const publishBadges = async (badgeId) =>{
      const apiURL = `/web-admin/badges/publish/${badgeId}?publish=true`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const updateBadgesStatus = async (badgeId, status) =>{
      const apiURL = `/web-admin/badges/status/${badgeId}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const updateBadge = async (data, badgeId) =>{
      const apiURL = `/web-admin/badges/${badgeId}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }

    const updateAdvertisementTypeStatus = async (id,status) =>{
      const apiURL = `/web-admin/advertisements/type/status/${id}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const publishAdvertisementType = async (advertisementTypeId) => {
      const apiURL = `/web-admin/advertisements/type/publish/${advertisementTypeId}?publish=true`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const updateJourneyGoalById = async(data,JourneyGoalId)=>{
      const apiURL = `/web-admin/journey-goals/${JourneyGoalId}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }

    const updateJourneyGoalStatus = async (id,status) =>{
      const apiURL = `/web-admin/journey-goals/status/${id}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const publishJourneyGoal = async (JourneyGoalId) => {
      const apiURL = `/web-admin/journey-goals/publish/${JourneyGoalId}?publish=true`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const updateAdvertisementTypeById = async(data,id)=>{
      const apiURL = `/web-admin/advertisements/type/${id}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }

    const updateAdvertisementById = async(data,advertisementId)=>{
      const apiURL = `/web-admin/advertisements/${advertisementId}`
      return await axios.put(apiURL,data, {header : svcconfig()})

    }
    const updateAdvertisementStatus = async (id,status) =>{
      const apiURL = `/web-admin/advertisements/status/${id}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const publishAdvertisement = async (id) => {
      const apiURL = `/web-admin/advertisements/publish/${id}?publish=true`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const updateNews = async(data,id)=>{
      const apiURL = `/web-admin/news/${id}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }

    const publishQRCode = async(id)=>{
      const apiURL = `/web-admin/qr-codes/business/publish/${id}?publish=true`
      return await axios.put(apiURL,{header : svcconfig()})
    }

    const updateQRCodeStatus = async(id,status)=>{
      const apiURL = `/web-admin/qr-codes/business/status/${id}?status=${status}`
      return await axios.put(apiURL,{header : svcconfig()})
    }

    const updateQRCodeById = async(data,id)=>{
      const apiURL = `/web-admin/qr-codes/business/${id}`
      return await axios.put(apiURL,data,{header : svcconfig()})
    }

    const updateUserPassword = async(data,id)=>{
      const apiURL = `/web-admin/users/changepassword/${id}`
      return await axios.put(apiURL, data, {header : svcconfig()})
    }
    
    const updateGeofenceBusinessStatus = async (geofencingId,status) =>{
      const apiURL = `/web-admin/geofencing/business/status/${geofencingId}?status=${status}`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const publishGeofenceBusiness = async (geofencingId) => {
      const apiURL = `/web-admin/geofencing/business/publish/${geofencingId}?publish=true`
      return await axios.put(apiURL, {header : svcconfig()})
    }

    const updateGeofenceBusiness = async (data,geofencingId)=>{
      const apiURL = `/web-admin/geofencing/business/${geofencingId}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }

    const updateNotification = async (data,notificationId)=>{
      const apiURL = `/web-admin/notification/${notificationId}`
      return await axios.put(apiURL,data, {header : svcconfig()})
    }
  
    return {
    updateWelcomeScreens,
    updateOnboardingScreens,
    updateCategory,
    updateCategoryStatus,
    publishCategory,
    updateOnboardingQuestions,
    updateOnboardingQuestionsStatus,
    publishOnboardingQuestions,
    updateCommuinityProfile,
    updateCommuinityProfileStatus,
    publishCommuinityProfile,
    updateToken,
    updateTokenStatus,
    updateSurvey,
    updateSurveyStatus,
    publishSurvey,
    updateSurveyQuestionStatus,
    updateSurveyQuestion,
    updateAsset,
    updateAssetStatus,
    publishAsset,
    updateUserStatus,
    publishBadges,
    updateBadgesStatus,
    updateBadge,
    updateJourneyGoalStatus,
    publishJourneyGoal,
    updateJourneyGoalById,
    updateAdvertisementTypeStatus,
    publishAdvertisementType,
    updateAdvertisementTypeById,
    updateAdvertisementById,
    updateAdvertisementStatus,
    publishAdvertisement,
    updateNews,
    publishQRCode,
    updateQRCodeStatus,
    updateQRCodeById,
    updateUserPassword,
    updateGeofenceBusinessStatus,
    publishGeofenceBusiness,
    updateGeofenceBusiness,
    updateNotification
    }
  }

export default usePutDataServices;