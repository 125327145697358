import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/CSS/sidebar.scss";
import "./assets/CSS/common.scss";
import "./assets/CSS/table.scss";
import "./assets/CSS/popupModal.scss";
import "./assets/CSS/login-form.scss";
import { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useNavigate } from "react-router";
import Sidemenu from "./components/common/Sidemenu";
import Header from "./components/common/Header";
import AdminWelcomScreen from "./components/screens/AdminWelcomScreen";
import OnboardingScreens from "./components/screens/OnboardingScreens";
import CategoryScreens from "./components/screens/CategoryScreens";
import UserListing from "./components/screens/UserListing";
import OnboardingQuestionsScreen from "./components/screens/OnboardingQuestionsScreen";
import ViewResults from "./components/screens/ViewResults";
import SurveyAddQuestion from "./components/screens/SurveyAddQuestion";
import CommunityProfile from "./components/screens/CommunityProfile";
import Login from "./components/authentication/Login";
import AuthContext from "./context/AuthProvider";
import TokenManagement from "./components/screens/TokenManagement";
import JourneyGoals from "./components/screens/JourneyGoals";
import AddJourneyGoals from "./components/screens/AddJourneyGoals";
import Badges from "./components/screens/Badges";
import NewsAnnouncement from "./components/screens/NewsAnnouncement";
import Survey from "./components/screens/Survey";
import LogoutOnlyRoutes from "./components/authentication/LogoutOnlyRoute";
import DashboardScreen from "./components/screens/DashboardScreen";
import ChangePasswordScreen from "./components/screens/ChangePasswordScreen";
import QrCode from "./components/screens/QrCode";
import AssetBox from "./components/screens/AssetBox";
import UserAsset from "./components/screens/UserAsset";
import WalletScreen from "./components/screens/WalletScreen";
import AddWalletAdvertise from "./components/screens/AddWalletAdvertise";
import Geofencing from "./components/screens/Geofencing";
import Notification from "./components/screens/Notification";

function App() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const { loggedIn } = authContext;

    const PathNotFound = () => {
        return navigate(-1);
    }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="row col-12 mx-0 px-0">
        <div className={loggedIn ? "col-auto px-0" : "d-none"}>
          <Sidemenu
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
          />
        </div>
        <div className="col px-0 main-screen">
          {loggedIn ? (
            <Header
              setToggleSidebar={setToggleSidebar}
              toggleSidebar={toggleSidebar}
            />
          ) : (
            ""
          )}
          <div className="m-4">
            <Routes>
              {!loggedIn ? (
                <Route element={<LogoutOnlyRoutes />}>
                  <Route path="/" element={<Login />} />
                </Route>
              ) : (
                <>
                  {/* Routes after logged in */}
                  <Route path="/" element={<AdminWelcomScreen />} />
                  <Route path="/onboarding" element={<OnboardingScreens />} />
                  <Route path="/alluser" element={<UserListing />} />
                  <Route path="/category" element={<CategoryScreens />} />
                  <Route path="/onboarding-questions" element={<OnboardingQuestionsScreen />} />
                  <Route path="/onboarding-questions/view-results" element={<ViewResults />} />
                  <Route path="/survey" element={<Survey />} />
                  <Route path="/survey/questions/:id" element={<SurveyAddQuestion />} />
                  <Route path="/survey/result/:id" element={<ViewResults />} />
                  {/* <Route path="/survey-view-result" element={<SurveyViewResult />} /> */}
                  <Route path="/community" element={<CommunityProfile />} />
                  <Route path="/token-management" element={<TokenManagement />} />
                  <Route path="/qr-code" element={<QrCode />} />
                  <Route path="/journey-goals" element={<JourneyGoals />} />
                  <Route path="/journey-goals/:categoryName/:categoryId" element={<AddJourneyGoals />} />
                  <Route path="/badges" element={<Badges />} />
                  <Route path="/news-announcement" element={<NewsAnnouncement />} />
                  <Route path="/assets" element={<AssetBox />} />
                  <Route path="/assets/user-asset" element={<UserAsset />} />
                  <Route path="/wallet" element={<WalletScreen />} />
                  <Route path="/wallet/advertise/:advertisementTypeName/:advertisementTypeId" element={<AddWalletAdvertise />} />
                  <Route path="/geofencing" element={<Geofencing />} />
                  <Route path="/notification" element={<Notification />} />
                </>
              )}
              {/*  Catch-all route for undefined routes  */}
                  <Route path="*" element={<PathNotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;