import useAxios from './useAxios';
import { svcconfig } from '../utilities/api-confige';

const useGetDataServices = () => {
    const axios = useAxios();

    const getAllWelcomeScreens = async () => {
      const apiURL = '/web-admin/welcomeScreen'
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getWelcomeScreensByID = async (id) => {
      const apiURL = `/web-admin/welcomeScreen/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAllOnboardingScreens = async () => {
      const apiURL = '/web-admin/onboardingScreen'
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getOnboardingScreenByID = async (id) => {
      const apiURL = `/web-admin/onboardingScreen/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAllCategories = async () => {
      const apiURL = '/web-admin/categories'
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getCategoryByID = async (id) => {
      const apiURL = `/web-admin/categories/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAllOnboardingQuestions = async (id) => {
      const apiURL = `/web-admin/onboardingQuestion`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getOnboardingQuestionsById = async (id) => {
      const apiURL = `/web-admin/onboardingQuestion/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getOnboardingQuestionResult = async (id) =>{
    const apiURL = `/web-admin/onboardingQuestion/answered/users`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getCommunityProfiles = async () => {
      const apiURL = `/web-admin/communities`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getCommunityProfilesById = async (id) => {
      const apiURL = `/web-admin/communities/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getOnboardingAnswers = async (id) => {
      const apiURL = `/web-admin/onboardingQuestion/answered/users`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getOnboardingUserAnswers = async (id) => {
      const apiURL = `/web-admin/onboardingQuestion/answered/users/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }
    
    const getAllTokens = async () => {
      const apiURL = `/web-admin/token-activities`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getTokenById = async (id) => {
      const apiURL = `/web-admin/token-activities/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAllSurveys = async () => {
      const apiURL = `/web-admin/survey`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getSurveyById = async (id) => {
      const apiURL = `/web-admin/survey/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAllSurveyQuestions = async (surveyId) => {
      const apiURL = `/web-admin/survey/questions/${surveyId}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getSurveyQuestionsById = async (questionId) => {
      const apiURL = `/web-admin/survey/question/${questionId}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getSurveyAnsweredUsers = async (surveyId) => {
      const apiURL = `/web-admin/survey/answered/users/${surveyId}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getSurveyAnswerByUser = async (userId,surveyId) => {
      const apiURL = `/web-admin/survey/answered/user/${userId}?surveyId=${surveyId}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAllAssets = async () => {
      const apiURL = `/web-admin/assets`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAssetsById = async (assetId) => {
      const apiURL = `/web-admin/assets/${assetId}`
      return await axios.get(apiURL, {header : svcconfig()})
    }
    
    const getUsersList = async () => {
      const apiURL = `/web-admin/users/`
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getAssetUserList = async () =>{
      const apiURL = `/web-admin/assets/users/list`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAssetsUserListById = async (id) =>{
      const apiURL = `/web-admin/assets/users/${id}`
      return await axios.get(apiURL,{header: svcconfig()})
    }
    const getAllBadges = async () =>{
      const apiURL = `/web-admin/badges/`
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getBadgeById = async (badgeId) =>{
      const apiURL = `/web-admin/badges/${badgeId}`
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getBadgeByCategoryId = async (categoryId) =>{
      const apiURL = `/web-admin/badges/type?criteriaType=OTHERS&categoryId=${categoryId}`
      return await axios.get(apiURL,{header : svcconfig()})
    }
    
    const getJourneyGoalsByCategoryId = async (id)=>{
      const apiURL=`/web-admin/journey-goals/category/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getJourneyGoalById = async (id)=>{
      const apiURL=`/web-admin/journey-goals/${id}`
      return await axios.get(apiURL, {header : svcconfig()})
    }

    const getAllAdvertisementTypes = async () =>{
      const apiURL = `/web-admin/advertisements/type`
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getAdvertisementTypebyId = async (id) =>{
      const apiURL = `/web-admin/advertisements/type/${id}`
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getAllAdvertisementsById = async (id) =>{
      const apiURL = `/web-admin/advertisements/fillter/${id}`
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getAdvertisementsById = async (id) =>{
      const apiURL = `/web-admin/advertisements/${id}`
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getAllNews = async () =>{
      const apiURL = `/web-admin/news/`;
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getNewsById = async (newsId) =>{
      const apiURL = `/web-admin/news/${newsId}`;
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getAllQRCodeBusiness = async () =>{
      const apiURL = `/web-admin/qr-codes/business`;
      return await axios.get(apiURL, {header : svcconfig()})
    }
    
    const getQRCodeBusinessById = async(QRcodeId) => {
      const apiURL = `/web-admin/qr-codes/business/${QRcodeId}`;
      return await axios.get(apiURL, {header : svcconfig()})
    }
    const getAllGeofenceBusinesses = async () =>{
      const apiURL = `/web-admin/geofencing/business`;
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getGeofenceBusinessById = async (newsId) =>{
      const apiURL = `/web-admin/geofencing/business/${newsId}`;
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getAllQRCodes = async () =>{
      const apiURL = `/web-admin/qr-codes/business`;
      return await axios.get(apiURL,{header : svcconfig()})
    }
    
    const getQRCodesById = async (qrCodeId) =>{
      const apiURL = `/web-admin/qr-codes/business/${qrCodeId}`;
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getAllNotifications = async () =>{
      const apiURL = `/web-admin/notification`;
      return await axios.get(apiURL,{header : svcconfig()})
    }

    const getNotificationById = async (id) =>{
      const apiURL = `/web-admin/notification/${id}`;
      return await axios.get(apiURL,{header : svcconfig()})
    }

  return {
    getAllWelcomeScreens,
    getWelcomeScreensByID,
    getAllOnboardingScreens,
    getOnboardingScreenByID,
    getAllCategories,
    getCategoryByID,
    getAllOnboardingQuestions,
    getOnboardingQuestionsById,
    getOnboardingQuestionResult,
    getOnboardingUserAnswers,
    getCommunityProfiles,
    getCommunityProfilesById,
    getOnboardingAnswers,
    getCommunityProfiles,
    getCommunityProfilesById,
    getAllTokens,
    getTokenById,
    getAllSurveys,
    getSurveyById,
    getAllSurveyQuestions,
    getSurveyQuestionsById,
    getSurveyAnsweredUsers,
    getSurveyAnswerByUser,
    getAllAssets,
    getAssetsById,
    getUsersList,
    getAssetUserList,
    getAssetsUserListById,
    getAllBadges,
    getBadgeById,
    getBadgeByCategoryId,
    getJourneyGoalsByCategoryId,
    getJourneyGoalById,
    getAllAdvertisementTypes,
    getAdvertisementTypebyId,
    getAllAdvertisementsById,
    getAdvertisementsById,
    getAllNews,
    getNewsById,
    getAllQRCodeBusiness,
    getQRCodeBusinessById,
    getAllGeofenceBusinesses,
    getGeofenceBusinessById,
    getAllQRCodes,
    getQRCodesById,
    getAllNotifications,
    getNotificationById
  }
}

export default useGetDataServices;