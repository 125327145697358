import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, InformationToast, SuccessToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";

const BadgeModal = (props) => {
    const modelRef = useRef();
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(false);
    const [criteriaLoader, setCriteriaLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [badgeInfo, setBadgeInfo] = useState({
      title: "",
      imageUrl: "",
      categoryId : "",
      criteriaType: "",
      criteriaValue: "",
      message: "",
    });
    const [invalidFields, setInvalidFields] = useState([]);
    const [updatedBadgeInfo, setUpdatedBadgeInfo] = useState({});
    const [validation, setValidation] = useState();
    const [criteriaType,setCriteriaType] = useState([]);
  
    const handleInputs = (e, id) => {
      const { name, value } = e.target;
      setBadgeInfo({ ...badgeInfo, 
        [name]: typeof value === "string" ? 
        value.trimStart().replace(/[^a-z\s]/ig, '').replace(/\s{2,}/g, ' ') : value
      });
      removeValidations();
    };

    const updateInputs = (...data) => {
      setUpdatedBadgeInfo({ ...updatedBadgeInfo, [data[0]]: data[1] });
      removeValidations();
    };

    const handleImageUpload = (file) => {
      setLoader(true);
      if (file?.type.startsWith("image/")) {
        PostApiServices.uploadImgToServer({ file: file })
          .then(async (res) =>{
            setBadgeInfo({ ...badgeInfo, imageUrl: res.data.Location })
            updateInputs("imageUrl", res.data.Location)
          })
          .catch((err) => ErrorToast("Problem while uploading image"))
          .finally(() => setLoader(false));
      } else {
        file && InformationToast("Please choose only image file to be uploaded.");
        setBadgeInfo({ ...badgeInfo, imageUrl: ""})
        setLoader(false);
      }
    };

    const handleAddUpdateData =(id)=>{
      let data = {
        title: badgeInfo.title,
        imageUrl: badgeInfo.imageUrl,
        categoryId : badgeInfo.categoryId,
        criteriaType: badgeInfo.criteriaType,
        criteriaValue: (badgeInfo.criteriaValue != "") ? badgeInfo.criteriaValue : null,
        message: badgeInfo.message,
      }
      if(props.id){
        UpdateApiServices.updateBadge(data,props.id).then((res)=>{
          props.setAddBadgeModal(false)
          res.status == 200 && SuccessToast("Badge Updated Sucessfully")
        })
        .catch((err)=>{
          ErrorToast('Something went wrong while Updating data')
        })
        .finally(() => setLoader(false))
      }else{
        PostApiServices.AddBadge(data).then((res)=>{
          props.setAddBadgeModal(false)
          res.status == 201 && SuccessToast("Badge Added Sucessfully")
        })
        .catch((err)=>{
          ErrorToast('Something went wrong while Adding data')
        })
        .finally(() => setLoader(false))
      }
    }

    const formValidation = () => {
      setLoader(true)
      setInvalidFields([])
      for (const key of Object.keys(badgeInfo)) {
        if (badgeInfo[key] === "") {
          if(key == 'criteriaValue'){
            if(badgeInfo.criteriaType === "OF_ASSETS" 
                || badgeInfo.criteriaType === "OF_TOKENS" 
                || badgeInfo.criteriaType === "OF_EVENTS_BUSINESS" 
                || badgeInfo.criteriaType === "OF_POSTS" 
                || badgeInfo.criteriaType === "OF_SURVEYS" 
                || badgeInfo.criteriaType === "OF_DAILY_LOGINS" ){
                  invalidFields.push(key)
                  setValidation(false)
                  setLoader(false)
                }
          }else{
            invalidFields.push(key)
            setValidation(false)
            setLoader(false)
          }
        }
      }
      if (validation && !invalidFields.length) {
        handleAddUpdateData();
      }
    };

     const removeValidations = () => {
      setValidation(true);
      setInvalidFields([]);
     }

     //Criteria option filteration (filter according to category)
     const handleCriteriaOptions =(categoryId)=>{
      console.log("criteria cateory",categoryId)
      setCriteriaLoader(true);
       const allCriteria = [
        "OF_ASSETS",
        "OF_TOKENS",
        "OF_EVENTS_BUSINESS",
        "OF_POSTS",
        "OF_SURVEYS",
        "OF_DAILY_LOGINS",
        "FOLLOWED",
        "COMPLETED_ONBOARDING",
        "COMPLETED_PROFILE",
        "COMPLETED_JOURNEY_GOALS",
        "FIRST_TO_COMPLETE_A_SURVEY",
        "OTHERS"
      ]

      allCriteria.length > 1 && GetApiServices.getAllBadges()
      .then(async (res) => {
        if(res.status === 200){
          let updatedCriteriaType = allCriteria;
          await res.data.map((item) => {
            if (categoryId === item.categoryId || badgeInfo.categoryId === item.categoryId){
              updatedCriteriaType = updatedCriteriaType.filter((criteria) => criteria !== item.criteriaType)
              if (!updatedCriteriaType.includes("OTHERS")) {
                updatedCriteriaType.push("OTHERS");
              }
            }
          });
          setCriteriaType(updatedCriteriaType);
          if(props.id){
            setCriteriaType([...updatedCriteriaType, badgeInfo.criteriaType])
          }
        }
        })
        .catch((error) => console.log("Error fetching badge criteria Types", error))
        .finally(() => setCriteriaLoader(false));
     }

    useEffect(()=>{
      GetApiServices.getAllCategories().then((res)=>setCategories(res.data))
      if(props.type === "Edit_Data" || props.type === "View_Data"){
      GetApiServices.getBadgeById(props.id).then((res)=>{
        const data = res.data;
        setBadgeInfo({
          title: data.title,
          imageUrl: data.imageUrl,
          categoryId : data.categoryId,
          criteriaType: data.criteriaType,
          criteriaValue: data.criteriaValue,
          message: data.message,
        })
      }).catch((err)=>{
        ErrorToast('Something went wrong while getting data')
      })
      }
      
    },[])

    useEffect(()=>{
      handleCriteriaOptions(badgeInfo.criteriaType);
    },[badgeInfo.criteriaType])


  return (
    <div>
    {
      console.log("badgeInfo",badgeInfo)
    }
    <Modal
      show={props.addBadgeModal}
      onHide={() => props.setAddBadgeModal(true)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
      <h5 className="modal-title">{props.type === "Edit_Data" ? "Edit" : props.type === "View_Data" ? "View" : "Add"} Badge</h5>
      </Modal.Header>
      <Modal.Body ref={modelRef} className="modal-height">
        {validation === false && (
          <div class="alert alert-danger fw-bold" role="alert">
            Please fill out all the fields !!!
            {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
          </div>
        )}
        <form>
          <div className="mb-4">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              disabled = {props.type === "View_Data"}
              value={badgeInfo.title}
              onChange={handleInputs}
            />
          </div>

          <div className="mb-4">
              <label htmlFor="image" className="form-label">
                Badge Graphic
              </label>
              <input
                type="file"
                className="form-control"
                id="imageUrl"
                name="imageUrl"
                accept=".jpg, .jpeg, .png"
                disabled={props.type === "View_Data"}
                onChange={(e) => handleImageUpload(e.target.files[0])}
                readOnly={props.actionType === "View_Data"}
              />
              <p className="text-muted">Note : Upload only image file</p>
              {badgeInfo.imageUrl ? (
                <div>
                  <p>Image Preview : </p>
                  <div className="row ms-2">
                    {loader ? (
                      <div className="h-75 col-5 text-center">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <img
                          src={badgeInfo.imageUrl}
                          className=" col-5 img-fluid border"
                        />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}             
            </div>

            <div className="mb-4">
            <label htmlFor="title" className="form-label">
                Category
              </label>
              <select className="form-select custom-select" 
              name="categoryId" 
              value={badgeInfo.categoryId} 
              disabled = {props.type === "View_Data"}
              onChange={(e)=>{
                setBadgeInfo({...badgeInfo,categoryId : e.target.value})
                handleCriteriaOptions(e.target.value)
                removeValidations()
              }}>
                  <option value="" disabled selected> Select Category</option>
                  {categories.length > 0 && 
                  categories.map((val)=>(
                      <option value={val.id} className={(val.isActive && val.isPublish)? '' : 'd-none'}>{val.name}</option>
                    ))
                  }
              </select>
          </div>

          <div className="mb-4">            
            <label htmlFor="title" className="form-label">
              Criteria of Achievement
            </label>
            <div className="row">
                <div className="col-8">
                {console.log("selected",criteriaType)}
                    <select
                        className="form-select" 
                        name="criteriaType" id = "criteriaType"
                        value={badgeInfo.criteriaType}
                        defaultValue={badgeInfo.criteriaType}
                        onChange={(e)=>{
                          setBadgeInfo({...badgeInfo,criteriaType : e.target.value})
                          removeValidations()
                        }}
                        disabled = {props.type === "View_Data"}
                        >
                       {criteriaLoader ? <p> Loading Criteria</p> : 
                       ( <>
                        <option value="" disabled selected> Select Criteria</option>
                        {criteriaType.map((curVal,index)=>
                          <option key={index} value={curVal}>{curVal}</option>
                        )}
                        </>
                        )}
                    </select>
                </div>
                {(badgeInfo.criteriaType === "OF_ASSETS" 
                || badgeInfo.criteriaType === "OF_TOKENS" 
                || badgeInfo.criteriaType === "OF_EVENTS_BUSINESS" 
                || badgeInfo.criteriaType === "OF_POSTS" 
                || badgeInfo.criteriaType === "OF_SURVEYS" 
                || badgeInfo.criteriaType === "OF_DAILY_LOGINS" ) &&
                  <div className="col-4">
                    <input
                    type="number"
                    className="form-control"
                    id="criteriaValue"
                    name="criteriaValue"
                    placeholder="#10000"
                    disabled = {props.type === "View_Data"}
                    value={badgeInfo.criteriaValue}
                    onChange={(e)=>{
                      setBadgeInfo({...badgeInfo,
                      criteriaValue : e.target.value})
                      removeValidations()
                    }}
                    />
                </div>}
            </div>
        </div>
            <div className="mb-2">
              <label htmlFor="image" className="form-label">
                Message
              </label>
              <textarea 
              cols="5" 
              className="form-control" 
              placeholder="Text Box where the message will be shown"
              id = "message"
              name="message"
              disabled={props.type === "View_Data"}
              value={badgeInfo.message}
              onChange={(e)=>{
                const { value } = e.target;
                const cleanedValue = value.trimStart().replace(/\s{2,}/g, ' ');
                setBadgeInfo({ ...badgeInfo, message: cleanedValue });
                removeValidations();
              }}
              ></textarea>            
            </div>
        </form>
      </Modal.Body>
      {!(props.type === "View_Data") &&
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={loader}
          onClick={formValidation}
        ><div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
            {props.type === "Add_Data" ? "Add" : "Update"}
          </p>
          </div>
        </Button>
      </Modal.Footer>}
    </Modal>
  </div>
  )
}

export default BadgeModal