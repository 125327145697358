import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  ErrorToast,
  InformationToast,
  SuccessToast,
} from "../utilities/notification";
import { convertHtmlToText, fetchData } from "../utilities/helpers";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import TextEditor from "../components/common/TextEditor";

const AddAssetModal = (props) => {
  const modelRef = useRef();
  const PostApiServices = usePostDataServices();
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const imageServices = useImageServices();
  const [loader, setLoader] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState();
  const [editorState,setEditorState]=useState("");
  const [categories, setCategories] = useState([]);
  const [updatedAssetInfo, setUpdatedAssetInfo] = useState({});
  const [assetData, setAssetData] = useState({
    name: "",
    overview: "",
    description: "",
    imageUrl: "",
    tokenAmount: 0,
    categoryId: "",
  });

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setAssetData({...assetData, 
      [name]:typeof value == "string" ? value.trimStart().replace(/\s{2,}/g, ' ') : value
    })
    updateInputs(name, value);
    removeValidations();
  };

  const onTextEditorChange = (e) =>{
    setEditorState(e)
    removeValidations();
  }

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  };
 
  const handleImageUpload = (file) => {
    setLoader(true);
    if (file?.type.startsWith("image/")) {
      PostApiServices.uploadImgToServer({ file: file })
        .then(async (res) =>{
          setAssetData({ ...assetData, imageUrl: res.data.Location })
          updateInputs("imageUrl", res.data.Location)
        })
        .catch((err) => ErrorToast("Problem while uploading image"))
        .finally(() => setLoader(false));
    } else {
      file && InformationToast("Please choose only image file to be uploaded.");
      setAssetData({ ...assetData, imageUrl: ""})
      setLoader(false);
    }
  };

  const updateInputs = (...data) => {
    setUpdatedAssetInfo({ ...updatedAssetInfo, [data[0]]: data[1] });
    removeValidations();
  };

  const datavalidation =()=>{
    setInvalidFields([])
    let valid = true;
    for(const keys of Object.keys(assetData)){
      if(assetData[keys] === ""){
        if(keys != 'description'){
          valid=false;
          modelRef.current.scrollTop = 0;
        }
        break
        }
      }
      let descriptionField = convertHtmlToText(editorState);
      if(descriptionField === ""){
        valid = false;
        modelRef.current.scrollTop = 0;
      }
      if(valid){
        handleSubmit();
      }
      else{
        setValidation(false);
      }
  }
  
  const handleSubmit = () => {
    setLoader(true)
    if(props.assetId){
      let data = {
        name: updatedAssetInfo.name,
        overview: updatedAssetInfo.overview,
        description: editorState,
        imageUrl: updatedAssetInfo.imageUrl,
        tokenAmount: updatedAssetInfo.tokenAmount,
        categoryId: updatedAssetInfo.categoryId,
      }
      UpdateApiServices.updateAsset(data, props.assetId)
      .then((res) => {
        props.setAddAssetModal(true);
        SuccessToast("Data updated successfully");
      })
      .catch((err)=>{ 
        console.error("error while updating asset",err)
        ErrorToast("Something went wrong while updating data");
      })
      .finally(()=> setLoader(false));
    }else{
      let data = {
        name: assetData.name,
        overview: assetData.overview,
        description: editorState,
        imageUrl: assetData.imageUrl,
        tokenAmount: assetData.tokenAmount,
        categoryId: assetData.categoryId,
      }
      PostApiServices.AddAsset(data)
      .then((res) => {
        SuccessToast("Data added successfully");
        props.setAddAssetModal(true)
      })
      .catch((err)=> {
        console.error("error while Adding asset",err);
        ErrorToast("Something went wrong while adding data")
      })
      .finally(()=> setLoader(false));
    }
  }
  useEffect(() => {
    fetchData(GetApiServices.getAllCategories(), setCategories, setLoader);
    if(props.assetId){
      GetApiServices.getAssetsById(props.assetId).then((res)=>{
        setAssetData(res.data)
        setEditorState(res.data.description)
      })
    }
  }, []);

  return (
    <div>
      <Modal
        show={props.addAssetModal}
        onHide={() => props.setAddAssetModal(true)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.actionType === "Add_Data" ? 'Add' : props.actionType === "Edit_Data" ? "Edit" : "View"} Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-height" ref={modelRef}>
          {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
              {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
            </div>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="name"
                value={assetData.name}
                onChange={(e)=>{
                  const { value } = e.target;
                  const cleanedValue = value.trimStart().replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s{2,}/g, ' ');
                  setAssetData({ ...assetData, name: cleanedValue });
                  updateInputs('name',cleanedValue)
                  removeValidations();
                }}
                disabled={props.actionType === "View_Data"}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Overview
              </label>
              <textarea 
              className="form-control"
              name="overview"
              value={assetData.overview}
              onChange={handleInputs}
              cols={3}
              disabled={props.actionType === "View_Data"}
              ></textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Description
              </label>
              <TextEditor
                  value={editorState} 
                  onTextEditorChange={onTextEditorChange}
                  readOnly={(props.actionType === "View_Data") ? true : false} />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Asset Icon
              </label>
              <input
                type="file"
                className="form-control"
                id="imageInput"
                name="imageInput"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => handleImageUpload(e.target.files[0])}
                disabled={props.actionType === "View_Data"}
              />
              <p className="text-muted">Note : Upload only image file</p>
            </div>
            
            {assetData.imageUrl ? (
              <>
                <p>Icon Preview</p>
                <div className="col-4 text-center mb-4">
                  {loader ? <Loader /> : <img src={assetData.imageUrl} className="img-fluid" />}
                </div>
              </>
            ) : (
              ""
            )}

            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Token Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="tokenAmount"
                value={assetData.tokenAmount}
                onChange={(e) => {
                  let tokenValue = e.target.value;
                    if (tokenValue.length < 6) {
                      if (/^\d*$/.test(tokenValue)) {
                        const parsedValue = parseInt(tokenValue, 10);
                        const newTokenAmount = !isNaN(parsedValue) ? Math.abs(parsedValue) : '';
                        setAssetData({ ...assetData, tokenAmount: newTokenAmount });
                        updateInputs("tokenAmount", newTokenAmount);
                        removeValidations()
                        }
                      }
                }
              }
                disabled={props.actionType === "View_Data"}
              />
            </div>
            
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Categories
              </label>
              <select
                className="form-select custom-select"
                name="categoryId"
                value={assetData.categoryId}
                onChange={handleInputs}
                disabled={props.actionType === "View_Data"}
              >
                <option value="" disabled selected>
                  {" "}
                  Select Category
                </option>
                {categories.length > 0 &&
                  categories.map((val) => (
                    <option
                      value={val.id}
                      className={val.isActive && val.isPublish ? "" : "d-none"}
                    >
                      {val.name}
                    </option>
                  ))}
              </select>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className={props.actionType === "View_Data" ? 'd-none' : ''}>
          <Button
            variant="primary"
            onClick={() => datavalidation()}
            disabled={loader}
          >
        <div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
          {props.actionType === "Edit_Data" ? "Update" : "Add"}
          </p>
          </div>
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddAssetModal;
