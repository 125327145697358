import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";

const WelcomeScreenModal = (props) => {
  const modelRef = useRef();
  const PostApiServices = usePostDataServices();
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const imageServices = useImageServices();
  const [loader, setLoader] = useState(false);
  const [setupScreenInputs, setSetupScreenInputs] = useState({
    title: "",
    content: "",
    imageUrl: "",
    buttonText: "",
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState();

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setSetupScreenInputs({ ...setupScreenInputs, [name]: typeof value == "string" ? 
    value.trimStart().replace(/\s{2,}/g, ' ') : value });
    removeValidations();
  };
  const formValidation = () => {
    for (const key of Object.keys(setupScreenInputs)) {
      if (setupScreenInputs[key] === "") {
        invalidFields.push(key)
        setValidation(false);
        modelRef.current.scrollTop = 0;
      }
    }
    if (validation && !invalidFields.length) {
      addEditWelcomeScreen();
    }
  };

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  };

  const addEditWelcomeScreen = async () => {
    setLoader(true);
    const filterInputFields = ["buttonText", "imageUrl", "content", "title"];
    for (let fileds of Object.keys(setupScreenInputs)) {
      if (!filterInputFields.includes(fileds)) {
        delete setupScreenInputs[fileds];
      }
    }
    // const userData = setupScreenInputs;

    if (props.type === "Add_Data") {
      setLoader(true);
      PostApiServices.AddWelcomeScreen(setupScreenInputs)
        .then(async (res) => {
          if (res.status === 201) {
            SuccessToast("Data Added Suucessfully.");
            setLoader(false);
            await props.setWelcomeSetupModal(true);
          } else {
            ErrorToast("Something went wrong");
          }
        })
        .catch((error) => {
          error.response.data.message.map((curError,index)=> ErrorToast(curError))
          console.log("add welcomeScreen error",error);
          setLoader(false);
        });
    }
    if (props.type === "Edit_Data") {
      UpdateApiServices.updateWelcomeScreens(setupScreenInputs, props.id)
        .then((res) => {
          SuccessToast("Data updated successfully !!!");
          props.setWelcomeSetupModal(true);
          setLoader(false);
        })
        .catch((error) => {
          console.log("put error", error);
          setLoader(false);
        });
    }
  };

  // purpose : const handleInputChange = (event) => {
  //     const {name, value} = event.target;
  //     setSetupScreenInputs({...setupScreenInputs,
  //     [name]:value
  //     })
  // }

  useEffect(() => {
    if (props.id) {
      GetApiServices.getWelcomeScreensByID(props.id)
        .then((res) => setSetupScreenInputs(res.data))
        .catch((error) => console.log("update welcome screen error : ", error));
    }
  }, [props.id]);

  return (
    <div>
      <Modal
        show={props.welcomeSetupModal}
        onHide={() => props.setWelcomeSetupModal(true)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title>{props.type === "Add_Data" ? 'Add' : "Edit" } welcome screen</Modal.Title>

        </Modal.Header>
        <Modal.Body ref={modelRef} className="modal-height">
          {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
              {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
            </div>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={setupScreenInputs.title}
                onChange={(e)=>{
                  const { value } = e.target;
                  const cleanedValue = value.trimStart().replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s{2,}/g, ' ');
                  setSetupScreenInputs({ ...setupScreenInputs, title: cleanedValue });
                  removeValidations();
                }}

              />
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                type="text"
                className="form-control"
                name="content"
                value={setupScreenInputs.content}
                rows={3}
                maxLength={50}
                onChange={handleInputs}
              ></textarea>
              <p className="text-muted">Maximum character length is 50</p>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Button Text
              </label>
              <input
                type="text"
                className="form-control"
                id="buttonText"
                name="buttonText"
                value={setupScreenInputs.buttonText}
                onChange={handleInputs}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="image" className="form-label">
                Screen Image
              </label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept="image/png, image/jpeg"
                name="imageUrl"
                onChange={(e) => {
                  imageServices.validateImageDimensions(
                    e.target.files[0],
                    setSetupScreenInputs,
                    setupScreenInputs,
                    setLoader
                  );
                  removeValidations()
                  // validateImageDimensions(e.target.files[0],setSetupScreenInputs,setupScreenInputs); uploadImageToserver(e.target.files[0]);
                }}
              />
              <p className="text-muted">
                Note : Image dimension should be 512 x 512
              </p>
            </div>

            {setupScreenInputs.imageUrl ? (
              <>
                <p>Image Preview</p>
                <div className="col-4 border">
                  <img src={setupScreenInputs.imageUrl} className="img-fluid" />
                </div>
              </>
            ) : (
              ""
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
          variant="primary"
          onClick={() => formValidation()}
          disabled={loader}
         >
         <div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
            {props.type === "Add_Data" ? "Add" : "Update"}
          </p>
          </div>
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WelcomeScreenModal;
