import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
// import 'react-phone-number-input/style.css'; 
import { SuccessToast, ErrorToast, InformationToast } from "../utilities/notification";
import countryCodes from 'country-codes-list'

const AddQrcodeModal = (props) => {
    const modelRef = useRef();
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const imageServices = useImageServices();
    const [loader, setLoader] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState(true);
    const [updatedQRBusinessData, setupdatedQRBusinessData] = useState({});
    const myCountryCodesObject = countryCodes.customList('countryCode', '+{countryCallingCode}')
    const countryCodesValue = Object.values(myCountryCodesObject)
    const [countrycodestate,setcountrycodestate]=useState("+1");
    const [QRBusinessData, setQRBusinessData]=useState({
        businessName : "",
        businessOwner : "",
        Address : "",
        zipCode : "",
        businessLogoUrl : "",
        longitude : "",
        latitude : "",
        mobileNumber : "",
        email : "",
        tokenAmount : "",
        discount : "",
        discountExpirationDate : "",
        reward : "",
        rewardExpirationDate : "",
        attachmentUrl : "",
        isCredited : null
    })

    const handleCountryChange = (e) => {
      const selectedCountryCode = e.target.value;
     setcountrycodestate(selectedCountryCode);
    };
    
    const handleInputs = (e) => {
      const { name, value } = e.target;
      setQRBusinessData((prevQRBusinessData) => ({
        ...prevQRBusinessData,
        [name]: typeof value === "string" ? value.trimStart().replace(/\s{2,}/g, ' ') : value
      }));
      
      updateInputs(name, value);
      removeValidations();
    };

    const updateInputs = (...data) => {
      setupdatedQRBusinessData({ ...updatedQRBusinessData, [data[0]]: data[1] });
      removeValidations();
    };
    
    const removeValidations = () => {
        setValidation(true);
        setInvalidFields([]);
      };
      
    const datavalidation = async()=>{
         setLoader(true);
        for(const keys of Object.keys(QRBusinessData)){
          if(typeof QRBusinessData[keys] === 'string' && QRBusinessData[keys].trim() === "" 
             || typeof QRBusinessData[keys] === 'number' && isNaN(QRBusinessData[keys])){
              if(keys !== "isCredited"){
                invalidFields.push(keys);
                setValidation(false);
                setLoader(false);
                modelRef.current.scrollTop = 0;
              }
          }

            if(keys === "businessName" && QRBusinessData[keys].length < 3){
                invalidFields.push(keys);
                setValidation(false);
                setLoader(false);
                modelRef.current.scrollTop = 0;
            }

            if(keys === "businessOwner" && QRBusinessData[keys].length < 3){
              invalidFields.push(keys);
              setValidation(false);
              setLoader(false);
              modelRef.current.scrollTop = 0;
            }

            if(keys === "Address" && QRBusinessData[keys].length < 3){
              invalidFields.push(keys);
              setValidation(false);
              setLoader(false);
              modelRef.current.scrollTop = 0;
            }

            if (!QRBusinessData.email) {
              setValidation(false);
              setLoader(false);
              modelRef.current.scrollTop = 0;
              return;
            }

            if (keys === 'email') {
              const emailRegex = /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z][a-zA-Z.-]*[a-zA-Z]\s*$/;

              if (!emailRegex.test(QRBusinessData.email)) {
                  setValidation(false);
                  setLoader(false);
                  modelRef.current.scrollTop = 0;
                  return;
              }
          }
        }

        if(validation && !invalidFields.length){
            handleAddEditData();
        }
        else{
            setValidation(false)
        }
    }

    const handleImageUpload = (file , field) => {
      setLoader(true);
      if (file?.type.startsWith("image/")) {
        PostApiServices.uploadImgToServer({ file: file })
          .then(async (res) =>{
            if(field == "businessLogoUrl"){
              setQRBusinessData({ ...QRBusinessData, businessLogoUrl: res.data.Location })
              updateInputs("businessLogoUrl", res.data.Location)
            }
            else if(field == "attachmentUrl"){
              setQRBusinessData({ ...QRBusinessData, attachmentUrl: res.data.Location })
              updateInputs("attachmentUrl", res.data.Location)
            }
          })
          .catch((err) => ErrorToast("Problem while uploading image"))
          .finally(() => setLoader(false));
      } else {
        file && InformationToast("Please choose only image file to be uploaded.");
        if(field === "businessLogoUrl"){
          setQRBusinessData({ ...QRBusinessData, businessLogoUrl: ""})
        }
        else{
          setQRBusinessData({...QRBusinessData,attachmentUrl:""})
        }
        setLoader(false);
      }
    };
      
    
    const handleAddEditData = () =>{
      let combinedMobileNumber;
      if(props.id) {
        combinedMobileNumber = `${countrycodestate} ${QRBusinessData.mobileNumber}`
      }else{
        combinedMobileNumber = `${countrycodestate} ${QRBusinessData.mobileNumber}`
      }
      let QRCodeData = {
          businessName : QRBusinessData.businessName,
          businessOwner : QRBusinessData.businessOwner,
          Address : QRBusinessData.Address,
          zipCode : QRBusinessData.zipCode.toString(),
          businessLogoUrl : QRBusinessData.businessLogoUrl,
          coordinates: {
            lat : QRBusinessData.latitude.toString(),
            long : QRBusinessData.longitude.toString(),
          },
          mobileNumber : combinedMobileNumber,
          email : QRBusinessData.email,
          tokenAmount : QRBusinessData.tokenAmount,
          discount : QRBusinessData.discount,
          discountExpirationDate : QRBusinessData.discountExpirationDate 
          ? new Date(QRBusinessData.discountExpirationDate).toISOString()
          : "",
          reward : QRBusinessData.reward,
          rewardExpirationDate : QRBusinessData.rewardExpirationDate
          ? new Date(QRBusinessData.rewardExpirationDate).toISOString()
          : "",
          attachmentUrl : QRBusinessData.attachmentUrl,
          isCredited : Boolean(QRBusinessData.isCredited)
        }
        if(props.id){
          UpdateApiServices.updateQRCodeById(QRCodeData,props.id).then((res)=>{
            props.setQrcodeModal(false)
            res.status == 200 && SuccessToast("QR Code Updated Successfully")
          })
          .catch((err)=>{
            ErrorToast(err.response.data.message[0])
          })
          .finally(()=>setLoader(false))
        }
        else{
          PostApiServices.addQRCode(QRCodeData).then((response)=>{
            props.setQrcodeModal(false)
            if (response.status === 201) {
              props.setQrcodeModal(false);
              SuccessToast("QR Code is Created Successfully");
            } else {
              ErrorToast("Something went wrong while creating QR Code !!!");
            }          
          })
          .catch((err)=>{
            ErrorToast(err.response.data.message[0])
          })
          .finally(()=>setLoader(false))
        }
    }
      
    useEffect(()=>{
      if(props.type === "Edit_Data" || props.type === "View_Data"){
        if(props.id){
          GetApiServices.getQRCodeBusinessById(props.id).then((res)=>{
            const data = res.data;
            setcountrycodestate(data.mobileNumber.split(" ")[0])
            setQRBusinessData({
              businessName : data.businessName,
              businessOwner : data.businessOwner,
                        Address : data.Address,
                        zipCode : data.zipCode,
                        businessLogoUrl : data.businessLogoUrl,
                        longitude : data.coordinates.long.toString(),
                        latitude : data.coordinates.lat,
                        mobileNumber : data.mobileNumber.split(" ")[1],
                        email : data.email,
                        tokenAmount : data.tokenAmount,
                        discount : data.discount,
                        discountExpirationDate : data.discountExpirationDate,
                        reward : data.reward,
                        rewardExpirationDate : data.rewardExpirationDate,
                        attachmentUrl : data.attachmentUrl,
                        isCredited : data.isCredited
                      })
               }).catch((err)=>{
                  ErrorToast('Something went wrong while getting data')
               })
            }
        }
    },[])
    return (
        <div>
          <Modal
            show={props.qrcodeModal}
            onHide={() => props.setQrcodeModal(true)}
            backdrop="static"
            keyboard={false}
            className="">
            <Modal.Header closeButton>
              <Modal.Title>{props.type == "Add_Data" ? "Add" : props.type == "Edit_Data" ? "Edit" : "View"} Affiliate QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body ref={modelRef} className="modal-height">
              {validation === false && (
                <div class="alert alert-danger fw-bold" role="alert">
                  Please fill out all the fields !!!
                  {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
                </div>
              )}
                <form>
                    <div className="mb-4 pt-2">
                        <label htmlFor="title" className="form-label mb-1">
                            Business Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="businessName"
                            name="businessName"   
                            value={QRBusinessData.businessName}                         
                            disabled={props.type === "View_Data"}
                            onChange={(e)=>{
                              const newBusinessName = e.target.value;
                              const valBusinessName = newBusinessName.trimStart().replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s{2,}/g, ' ');
                              if(/^[a-zA-Z0-9, -]*$/.test(valBusinessName)){
                                setQRBusinessData({...QRBusinessData,businessName:valBusinessName})
                                updateInputs("businessName",valBusinessName)
                                removeValidations()
                              }
                            }}
                        />
                    </div> 
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                            Business Owner
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="businessOwner"
                            name="businessOwner"
                            value={QRBusinessData.businessOwner}                         
                            disabled={props.type === "View_Data"}                            
                            onChange={(e)=>{
                              const newBusinessOwner = e.target.value;
                              const valBusinessOwner= newBusinessOwner.trimStart().replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s{2,}/g, ' ');
                              if(/^[a-zA-Z0-9, -]*$/.test(valBusinessOwner)){
                                setQRBusinessData({...QRBusinessData,businessOwner:valBusinessOwner})
                                updateInputs("businessOwner",valBusinessOwner)
                              }
                              removeValidations()
                            }}                        />
                    </div> 
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                            Address
                        </label>
                        <textarea
                            className="form-control"
                            id="Address"
                            name="Address"
                            value={QRBusinessData.Address}    
                            cols={3}                     
                            disabled={props.type === "View_Data"}                         
                            required
                            onChange={(e) => {
                              const newAddress = e.target.value;
                              const valAddress = newAddress.trimStart().replace(/[^a-zA-Z0-9\s,.\-]/g, '').replace(/\s{2,}/g, ' ');
                              setQRBusinessData({ ...QRBusinessData, Address: valAddress });
                              updateInputs("Address", valAddress);
                              removeValidations();
                            }}
                            
                        >
                        </textarea>
                    </div>     
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                            Zipcode
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="zipCode"
                            name="zipCode" 
                            value={QRBusinessData.zipCode}                         
                            disabled={props.type === "View_Data"}
                            onChange={(e) => {
                              let ZipcodeValue = e.target.value;
                                if (ZipcodeValue.length <= 5) {
                                  if (/^\d*$/.test(ZipcodeValue)) {
                                    const parsedValue = parseInt(ZipcodeValue, 10);
                                    const newZipCode = !isNaN(parsedValue) ? Math.abs(parsedValue) : '';
                                    setQRBusinessData({ ...QRBusinessData, zipCode: newZipCode });
                                    updateInputs("zipCode", newZipCode);
                                    removeValidations();
                                    }
                                  }
                            }}                          
                    />
                    </div>  
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label">
                            Logo
                        </label>
                        <input
                            type="file"
                            className="form-control"
                            id="businessLogoUrl"
                            accept="image/png, image/jpeg"
                            name="businessLogoUrl"
                            disabled={props.type === "View_Data"}
                            onChange={(e) => handleImageUpload(e.target.files[0],"businessLogoUrl")}
                        />
                        <p className="text-muted">Note : Upload only image file</p>
                    </div>  
                    {QRBusinessData.businessLogoUrl ? (
                       <>
                        <p>Image Preview</p>
                        <div className="col-4 border">
                          <img src={QRBusinessData.businessLogoUrl} className="img-fluid" />
                        </div>
                        <br/>
                      </>
                    ) : (
                      ""
                    )}
                 
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                          Longitude
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="longitude"
                            name="longitude" 
                            value={QRBusinessData.longitude}
                            onChange={(e) => {
                              const {name,value}=e.target
                              if (/^[-0-9.]+$/.test(value) || value === '') {
                                setQRBusinessData({ ...QRBusinessData, longitude: value });
                                updateInputs(name, value);
                                removeValidations()
                              }
                            }}
                            disabled={props.type === "View_Data"}                            
                        />
                        <p className="text-muted">Note : Longitude must be in a valid format i.e -95.358421 </p>
                    </div> 

                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                          Latitude
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="latitude"
                            name="latitude" 
                            value={QRBusinessData.latitude}    
                            disabled={props.type === "View_Data"}                            
                            onChange={(e) => {
                              const {name,value}=e.target
                              if (/^[-0-9.]+$/.test(value) || value === '') {
                                setQRBusinessData({ ...QRBusinessData, latitude: value });
                                updateInputs(name, value);
                                removeValidations()
                              }
                            }}                          
                        />
                        <p className="text-muted">Note : Latitude must be in a valid format i.e 29.749907</p>
                    </div> 

                    <div className="mb-4">
                    <label htmlFor="title" className="form-label mb-1">
                            Mobile Number
                        </label>
                        <div className="input_number d-flex">
                          <div className="col-2 pe-2">
                              <select className="form-control" defaultValue="+1" value={countrycodestate} onChange={handleCountryChange}>
                                {countryCodesValue && countryCodesValue.map((countryCode,index) => (
                                    <option key={index} value={countryCode}>
                                      {countryCode}
                                    </option>
                                ))}
                            </select>
                          </div>
                          <div className="col">
                          <input
                                type="text"
                                className="form-control"
                                id="mobileNumber"
                                name="mobileNumber"
                                value={QRBusinessData.mobileNumber}                         
                                disabled={props.type === "View_Data"}
                                onChange={(e)=>{
                                  console.log(e.target.value)
                                  const {name, value} = e.target
                                  if(/^[0-9]*$/.test(value)){
                                    console.log("mobile",value)
                                    setQRBusinessData({...QRBusinessData, mobileNumber: value})
                                    updateInputs(name, value);
                                    removeValidations()
                                  }
                                }

                                }
                                maxLength={10}
                            /> 
                          </div>
                        </div>
                        
                        </div>    
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                            Email
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"    
                            value={QRBusinessData.email}                         
                            disabled={props.type === "View_Data"}                        
                            onChange={(e)=>{
                              const { value } = e.target;
                              const cleanedValue = value.trim().replace(/\s{2,}/g, ' ');
                              setQRBusinessData({ ...QRBusinessData, email: cleanedValue });
                              updateInputs("email", cleanedValue);
                              removeValidations();
                            }}
                        />
                    </div>  

                    <div className="mb-4">
                    <label htmlFor="title" className="form-label mb-1">
                           Token Amount
                    </label>
                    <input
                          type="text"
                          className="form-control"
                          id="tokenAmount"
                          name="tokenAmount"
                          value={QRBusinessData.tokenAmount}
                          disabled={props.type === "View_Data"}
                          onChange={(e) => {
                            let tokenValue = e.target.value;
                              if (tokenValue.length < 6) {
                                if (/^\d*$/.test(tokenValue)) {
                                  const parsedValue = parseInt(tokenValue, 10);
                                  const newTokenAmount = !isNaN(parsedValue) ? Math.abs(parsedValue) : '';
                                  setQRBusinessData({ ...QRBusinessData, tokenAmount: newTokenAmount });
                                  updateInputs("tokenAmount", newTokenAmount);
                                  removeValidations();
                                  }
                                }
                          }}
                     />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="title" className="form-label mb-1">
                        Add/Subtract
                      </label>
                      <select
                        className="form-select"
                        onChange={(e)=>{
                          if(e.target.value === "true"){
                            setQRBusinessData({...QRBusinessData,isCredited:true})
                            updateInputs("isCredited",e.target.value)
                          }
                          else{
                            setQRBusinessData({...QRBusinessData,isCredited:false})
                            updateInputs("isCredited",e.target.value)
                          }
                          removeValidations()
                        }}
                        disabled = {props.type === "View_Data"}
                        name="isCredited" 
                        id = "isCredited"
                        value={QRBusinessData.isCredited}
                      >
                          <option value="" selected disabled>Select Option</option>
                          <option value={true}>Add</option>
                          <option value={false}>Subtract</option>
                      </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                            Discount (%)
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="discount"
                            name="discount"  
                            value={QRBusinessData.discount}                         
                            disabled={props.type === "View_Data"}                       
                            onChange={(e)=>{
                              const newDiscount = e.target.value;
                                if(/^[0-9]*$/.test(newDiscount)){
                                    setQRBusinessData({ ...QRBusinessData, discount: newDiscount });
                                    updateInputs("discount", newDiscount)
                                }
                              removeValidations()
                              }}                        
                        />
                    </div>  
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                            Discount Expiration Date
                        </label>
                        <input
                            type="date"
                            className="form-control p-2"
                            name="discountExpirationDate"
                            onClick={(e)=>e.target.showPicker()}
                            onKeyDown={(e)=>e.target.showPicker()}
                            min={new Date().toISOString().split("T")[0]}
                            value={QRBusinessData?.discountExpirationDate?.split("T")[0]}
                            onChange={(e) => {
                              setQRBusinessData({
                                ...QRBusinessData,
                                discountExpirationDate: `${e.target.value}T00:00:00.000Z`,
                              });
                              removeValidations()
                              updateInputs("discountExpirationDate", `${e.target.value}T00:00:00.000Z`);
                            }}
                            disabled={props.type === "View_Data"}                       
                        />                       
                    </div> 
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                            Reward
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="reward"
                            name="reward"
                            value={QRBusinessData.reward}                         
                            disabled={props.type === "View_Data"}
                            // onChange={(e)=>{
                            //   const newReward = e.target.value;
                            //     if(/^[a-zA-Z0-9]*$/.test(newReward)){
                            //         setQRBusinessData({ ...QRBusinessData, reward: newReward });
                            //         updateInputs("reward", newReward)
                            //     }
                            //   removeValidations()
                            //   }}
                            onChange={handleInputs}
                        />
                    </div>  
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label mb-1">
                            Reward Expiration Date
                        </label>
                        <input
                            type="date"
                            className="form-control cal"
                            name="rewardExpirationDate"
                            onClick={(e)=>e.target.showPicker()}
                            onKeyDown={(e)=>e.target.showPicker()}
                            min={new Date().toISOString().split("T")[0]}
                            value={QRBusinessData?.rewardExpirationDate?.split("T")[0]}
                            onChange={(e) => {
                              setQRBusinessData({
                                ...QRBusinessData,
                                rewardExpirationDate: `${e.target.value}T00:00:00.000Z`,
                              });
                              removeValidations()
                              updateInputs("rewardExpirationDate", `${e.target.value}T00:00:00.000Z`);
                            }}
                            disabled={props.type === "View_Data"}                       
                        />                       
                    </div>   
                    <div className="mb-4">
                        <label htmlFor="title" className="form-label">
                            Attachment
                        </label>
                        <input
                            type="file"
                            className="form-control"
                            accept="image/png, image/jpeg"
                            id="attachmentUrl"
                            name="attachmentUrl"
                            disabled={props.type === "View_Data"}
                            onChange={(e) => handleImageUpload(e.target.files[0],"attachmentUrl")}
                        />
                        <p className="text-muted">Note : Upload only image file</p>
                    </div>
                    {QRBusinessData.attachmentUrl ? (
                       <>
                        <p>Image Preview</p>
                        <div className="col-4 border">
                          <img src={QRBusinessData.attachmentUrl} className="img-fluid" />
                        </div><br/>
                      </>
                    ) : (
                      ""
                    )} 
                      

                    {/* <div className="d-flex justify-content-end">    
                    <button class="btn btn-outline-primary col-auto me-2">Verify</button>
                    </div> */}
                </form>
            </Modal.Body>
            <Modal.Footer align="center" className={`${props.type === "View_Data" && 'd-none'}`}>
              {/* <Button
                variant="primary"
                // onClick={() => formValidation()}
                disabled={loader}
              >
                {loader ? <Loader /> : ""}
                <p className="d-inline mx-2">
                  {props.type === "Add_Data" ? "Add" : "Update"}
                </p>
              </Button> */}
              <Button
                variant="primary"
                disabled={loader}
                 onClick={() => datavalidation()}
                ><div className="d-flex align-center">
                {loader ? 
                <>
                <div className="center"><div className="loader"></div></div>
                </>
                : ""}
                <p className="d-inline mx-2 my-0">
                    {props.type === "Add_Data" ? "Add" : "Update"}
                </p>
                </div>
                </Button>
            </Modal.Footer>
            
          </Modal>
        </div>
      );
}

export default AddQrcodeModal