import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../popupModals/DeleteModal";
import AddJourneyModal from "../../popupModals/AddJourneyModal";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useNavigate, useParams } from "react-router";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";

const AddJourneyGoals = () => {
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const UpdateApiServices = usePutDataServices();
    const [categoryDetails, setCategoryDetails] = useState();
    const [CategoryJourneyGoal, setCategoryJourneyGoal] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [disableToggle, setDisableToggle] = useState();
    const params = useParams();
    const [addJourneyModal, setAddJourneyModal] = useState({
      visibility: false,
      type: "",
      id:""
    });
    const [deleteModal, setDeleteModal] = useState({
      visibility: false,
      deleteItem: "",
      id:""
    });

    const handleAddEditData = (type,id) =>{
        setAddJourneyModal({
        ...addJourneyModal,
        visibility: true,
        type,
        id
      });
    }
  
    const handlePublish = (id) =>{
      UpdateApiServices.publishJourneyGoal(id)
      .then((res)=>{
        SuccessToast("Journey Goal Published Successfully");
        fetchData(GetApiServices.getJourneyGoalsByCategoryId(params.categoryId), setCategoryJourneyGoal, setLoader)
      })
      .catch((err)=>{
        ErrorToast('Something Went Wrong while publishing');
      });
    };
 
    const handleStatus =(data)=>{
      setDisableToggle(true)
      let status = data.isActive ? false : true ;
      UpdateApiServices.updateJourneyGoalStatus(data.id,status)
      .then((res)=>{
        SuccessToast(`Status changed successfully`)
        fetchData(GetApiServices.getJourneyGoalsByCategoryId(data.categoryId), setCategoryJourneyGoal, setLoader)
      })
      .catch((err)=>{
        ErrorToast('Something Went Wrong');
      })
      .finally(()=>setDisableToggle(false))
    }

    const fetchCategoryJourneyGoal = (id) =>{
      if(id){
        fetchData(GetApiServices.getJourneyGoalsByCategoryId(id),setCategoryJourneyGoal,setLoader)
      }
    }
    useEffect(()=>{
      GetApiServices.getCategoryByID(params.categoryId).then((res)=>setCategoryDetails(res.data))
      fetchCategoryJourneyGoal(params.categoryId)
    },[addJourneyModal])

    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col">
              <h4 className="text-primary fw-bold">{params.categoryName} Journey Goals</h4>
            </div>
            <div class="col-auto">
                <button class="btn btn-outline-secondary col-auto add-button" onClick={() => navigate("/journey-goals")}>Back</button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-outline-primary  add-button col-auto me-2"
                onClick={() => handleAddEditData("Add_Data")}
              >
                Add Journey Goal
              </button>
             
            </div>            
            
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table class="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col" className="col">Goal Name</th>
                    <th scope="col" className="col">Token Amount</th>
                    <th scope="col" className="col">Status</th>
                    <th scope="col" className="col text-center">Publish</th>
                    <th scope="col" className="col text-center">Action</th>
                    <th scope="col" className=""></th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {
                    loader ? (
                      <tr className="my-5 text-center">
                        <th colSpan="6" className="py-5"><Loader/></th>
                      </tr>
                    ) : CategoryJourneyGoal.length ? (
                        CategoryJourneyGoal.map((curVal,index)=>(
                          <tr key={index}>
                            <td>{curVal.name}</td>
                            <td>{curVal.tokenAmount}</td>
                            <td className="">
                               <div className="form-switch">
                                    <input className="form-check-input custom-control-input" type="checkbox" role="switch" disabled={!curVal.isPublish || disableToggle}
                                     checked={curVal.isActive} onChange={()=>handleStatus(curVal)}/>
                               </div>
                           </td>
                           <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={curVal.isPublish}
                            checked={curVal.isPublish} onClick={()=>handlePublish(curVal.id)}
                             />
                          </div>
                        </td>
                           <td className="text-center">
                      <div className="btn-group">
                          <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul className="dropdown-menu py-0">
                           {curVal.isPublish ?
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${curVal.id}`) } >
                              <i className="fa-solid fa-eye"></i> View
                              </li>
                            </div>
                            :
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${curVal.id}`) } >
                              <i className="fa-solid fa-pen-to-square"></i> Edit
                              </li>
                            </div>}
                          </ul>
                        </div>
                    </td>
                          </tr>
                        ))
                    ) : (
                      <>
                    <tr className="my-5 text-center">
                      <td colSpan="4">
                        <h4>No Data Available</h4>
                      </td>
                    </tr>
                  </>
                    )
                  }                                            
                </tbody>
              </table>
            </div>
          </div>
          {addJourneyModal.visibility ? (
            <AddJourneyModal
              type={addJourneyModal.type}
              id={addJourneyModal.id}
              addJourneyModal={addJourneyModal.visibility}
              setAddJourneyModal={setAddJourneyModal}
              categoryId={params.categoryId}
              categoryName={categoryDetails.name}
            />
          ) : (
            ""
          )}
          {deleteModal.visibility ? (
            <ConfirmationModal
              deleteItem={deleteModal.deleteItem}
              deleteModal={deleteModal.visibility}
              setDeleteModal={setDeleteModal}
              setLoader={setLoader}
              id={deleteModal.id}
              deleteDataApi={() => DeleteApiServices.deleteOnboardingScreen(deleteModal.id)}
              // setNewData={setUserList}
              // fetchNewDataApi={() => GetApiServices.getAlluserlist()}
            />
          ) : (
            ""
          )}
        </div>
        </>
      );
}

export default AddJourneyGoals