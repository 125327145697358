import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import usePutDataServices from "../../hooks/usePutDataServices";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import Loader from "../common/Loader";
import { useNavigate } from "react-router";

import NotificationModal from "../../popupModals/NotificationModal";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import ConfirmationModal from "../../popupModals/DeleteModal";
import usePostDataServices from "../../hooks/usePostData";

const Notification = () => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [loader, setLoader] = useState(true);
  const GetApiServices = useGetDataServices();
  const DeleteApiServices = useDeleteDataServices();
  const PostApiServices = usePostDataServices();
  const UpdateApiServices = usePutDataServices();

  const [notificationModal, setNotificationModal] = useState({
    visibility: false,
    type: "",
    id: "",
  });

  const [deleteModal, setDeleteModal] = useState({
    visibility:false,
    deleteItem:"",
    id:""
  })

  const handleAddEditData = (type, id) => {
    setNotificationModal({
      ...notificationModal,
      visibility: true,
      type,
      id,
    });
  };

  const handleDeleteModal = (data,id) => {
    setDeleteModal({...deleteModal, visibility:true, deleteItem:data, id})
  }

  const handleSendNotification = (id) => {
    PostApiServices.sendNotification(id)
    .then((res)=>{
      SuccessToast("Notification has been sent.")
    })
    .catch((err)=>{
      ErrorToast("Something went wrong while sending the notification.")
    })
    .finally(()=>{
      fetchData(
        GetApiServices.getAllNotifications(),
        setAllNotifications,
        setLoader
      );
    })
  }

  useEffect(() => {
    fetchData(
      GetApiServices.getAllNotifications(),
      setAllNotifications,
      setLoader
    );
  }, [notificationModal, deleteModal]);

  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">Notification</h4>
          </div>
          <div className="col-auto pe-1">
            <button
              className="btn btn-primary add-button col-auto"
              onClick={() => handleAddEditData("Add_Data")}
            >
              Add Notification
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 col-md-12 table-responsive fixed-table"
            style={{ overflowX: "auto" }}
          >
            <table class="table table-fit  align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className=" table-header-font align-middle">
                  <th scope="col" className="col-2">Title</th>
                  <th scope="col" className="col-3">Description</th>
                  <th scope="col" className="col-2">Criteria</th>
                  <th scope="col" className="col-2">Notification Type</th>
                  <th scope="col" className="text-center">Status</th>
                  <th scope="col" className="text-center">Action</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              {loader ? (
                <tr className=" my-5 text-center">
                  <th colSpan="6" className="py-5">
                    <Loader />
                  </th>
                </tr>
              ) : allNotifications.length > 0 ? (
                allNotifications.map((item,index) => (
                  <tbody className="" key={index}>
                    <tr>
                      <td className="col-2">{item.title}</td>
                      <td className="col-3">{item.body}</td>
                      <td className="col-2">{item.criteria}</td>
                      <td className="col-2">{item.type}</td>
                      <td className="text-center">

                        {/* <div className="form-switch">
                          <input
                            className="form-check-input custom-control-input"
                            type="checkbox"
                            role="switch"
                            value={item.status}
                          /> 
                        </div>*/}

                        <button className="btn btn-outline-primary py-1 px-3 badge w-100 cursor-none disabled" disabled>{item.status}</button>
                        {/* <button className="btn-outline-danger badge">Incomplete</button> */}
                      </td>
                      <td className="col text-center">
                        <button className="btn btn-primary edit-button" disabled={item.status === "INPROGRESS"} onClick={()=> handleSendNotification(item.id)}>
                          Send
                        </button>
                      </td>

                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="dot"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul className="dropdown-menu py-0 px-0">
                            <li className={item.status === "PENDING" && 'd-none'}>
                              <a className="dropdown-item" onClick={() => handleAddEditData("View_Data",item.id)}>
                                <i className="fa-solid fa-eye"></i> View
                              </a>
                            </li>
                            <li className={item.status === "INPROGRESS" && 'd-none'}>
                              <a className="dropdown-item" onClick={() => handleAddEditData("Edit_Data",item.id)}>
                                <i className="fa-solid fa-pen-to-square"></i>{" "}
                                Edit
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" onClick={()=> handleDeleteModal("",item.id)}>
                                <i className="fa-solid fa-trash"></i> Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    {/* ))
                ) : null} */}
                  </tbody>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan={6} className="text-center py-5">
                      <h4 className="text-muted">No Data Available</h4>
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
        </div>
        {notificationModal.visibility ? (
          <NotificationModal
            notificationModal={notificationModal}
            setNotificationModal={setNotificationModal}
            id={notificationModal.id}
            type={notificationModal.type}
          />
        ) : (
          ""
        )}
        {deleteModal.visibility ? (
          <ConfirmationModal
            deleteItem={deleteModal.deleteItem}
            deleteModal={deleteModal.visibility}
            setDeleteModal={setDeleteModal}
            setLoader={setLoader}
            id={deleteModal.id}
            deleteDataApi={() => DeleteApiServices.deleteNotification(deleteModal.id)}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Notification;
