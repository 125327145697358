import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import { fetchData } from "../utilities/helpers";
import { ErrorToast, InformationToast, SuccessToast } from "../utilities/notification";

const AddBusinessModal = (props) => {
  const modelRef = useRef();
  const PostApiServices = usePostDataServices();
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const imageServices = useImageServices();
  const [loader, setLoader] = useState(false);
  const [BusinessDataLoader, setBusinessDataLoader] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState();
  const [geofencingPost, setGeofencingPost] = useState({
    qrCodeId: "",
    message: "",
    countLimit: "",
    radius: "",
    qrCode: {
      id: "",
      businessName: "",
      Address: "",
      coordinates: {
        lat: "",
        long: ""
      },
      tokenAmount: "",
    }
  })
  const [selectedBusinessData, setSelectedBusinessData] = useState()
  const [allBusinesses, setAllBusinesses] = useState([]);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setGeofencingPost({ ...geofencingPost, [name]: value });
    removeValidations();
  };

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  };

  const formValidation = () => {
    let data = {
      qrCodeId: geofencingPost?.qrCodeId ? geofencingPost?.qrCodeId : geofencingPost?.id || "",
      // qrCodeId: geofencingPost.qrCodeId || "",
      radius: Number(geofencingPost.radius),
      countLimit: Number(geofencingPost.countLimit),
      message: geofencingPost.message,
    }
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] === null || data[key] === undefined 
         || data[key] === 0 ||  typeof data[key] === 'number' && isNaN(data[key])) {
        setValidation(false);
        invalidFields.push(key);
        modelRef.current.scrollTop = 0;
      }
    });
    if (validation && !invalidFields.length) {
      handleSubmit(data)
    }
  }

  const handleSubmit = (data) => {
    setLoader(true);

    if (props.id) {
      UpdateApiServices.updateGeofenceBusiness(data, props.id)
        .then((res) => {
          SuccessToast("Advertisement updated successfully");
          props.setAddBusinessModal(false);
        })
        .catch((err) => {
          ErrorToast("Somthing went wrong while updating data.")
          console.error("err while posting geofence", err)
        })
        .finally(() => setLoader(false));
    } else {
      PostApiServices.addGeofencingBusiness(data)
        .then((res) => {
          SuccessToast("Advertisement added successfully")
          props.setAddBusinessModal(false)
        })
        .catch((err) => {
          ErrorToast("Somthing went wrong while adding data.")
          console.error("err while posting geofence", err)
        })
        .finally(() => setLoader(false))
    }
  }

  const fetchSelectedBusinessData = (id) => {
    GetApiServices.getQRCodesById(id)
      .then((res) => {
        setGeofencingPost({ ...geofencingPost, ...res.data, qrCodeId: id })
      })
      .catch((err) => console.error("error", err))
  }

  useEffect(() => {
    setBusinessDataLoader(true);
    fetchData(GetApiServices.getAllQRCodes(), setAllBusinesses, setBusinessDataLoader)
    if (props.id) {
      fetchData(GetApiServices.getGeofenceBusinessById(props.id), setGeofencingPost, setLoader)
    }
  }, [])

  return (
    <div>
      <Modal
        show={props.addBusinessModal}
        onHide={() => props.setAddBusinessModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.type === "Add_Data" ? "Add" : props.type === "Edit_Data" ? "Edit" : "View"} Business</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modelRef} className="modal-height">
          {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
              {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
            </div>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Business Name
              </label>
              <select
                className="form-select custom-select"
                name="qrCodeId"
                value={geofencingPost.qrCodeId}
                onChange={(e) => {
                  setGeofencingPost({ ...geofencingPost, qrCodeId: e.target.value });
                  fetchSelectedBusinessData(e.target.value);
                  removeValidations();
                }}
                disabled={props.type === "View_Data"}
              >
                {
                  BusinessDataLoader ?
                    <option value="" disabled selected>
                      Loading Businesses
                    </option> : (
                      allBusinesses.length > 0 ?
                        (
                          <>
                        <option value="" disabled selected>
                          Select Business Name
                        </option>
                        {allBusinesses.map((val) => (
                                <option
                                  value={val.id}
                                  className={val.isActive && val.isPublish ? "" : "d-none"}
                                >
                                  {val.businessName}
                                </option>
                              ))}
                              </>) : (
                            <option value="" disabled selected>
                            No data Available
                            </option>
                        ))
                        }
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Address
              </label>
              <textarea className="form-control" name="Address"
                value={geofencingPost?.Address || geofencingPost?.qrCode?.Address}
                disabled></textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Longitude
              </label>
              <input
                type="text"
                className="form-control"
                name="long"
                value={geofencingPost?.coordinates?.long || geofencingPost?.qrCode?.coordinates?.long}
                disabled
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Latitude
              </label>
              <input
                type="text"
                className="form-control"
                name="lat"
                value={geofencingPost?.coordinates?.lat || geofencingPost?.qrCode?.coordinates?.lat}
                disabled
              />
            </div>
            
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Token Amount
              </label>
              <input
                type="number"
                className="form-control"
                name="tokenAmount"
                value={geofencingPost?.tokenAmount || geofencingPost?.qrCode?.tokenAmount}
                disabled
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Expiration Date
              </label>
              <input type="date" 
                className="form-control cal"
                onClick={(e)=>e.target.showPicker()}
                onKeyDown={(e)=>e.target.showPicker()}
                name="discountExpirationDate"
                value={(geofencingPost?.discountExpirationDate || geofencingPost?.qrCode?.discountExpirationDate)?.toString().split('T')[0]}
                disabled
              />
            </div>
            <div className="mb-4">
              <label htmlFor="radius" className="form-label mb-1">
                Radius (In Miles)
              </label>
              <input
                type="text"
                className="form-control"
                name="radius"
                value={geofencingPost.radius}
                onChange={(e)=>{
                  const newRadius = e.target.value;
                  if (newRadius.length < 4) {
                    if(/^\d*$/.test(newRadius)){
                      if(newRadius < 1000){
                        setGeofencingPost({ ...geofencingPost, radius: newRadius });
                      }
                      else{
                        InformationToast("Radius should be under 1000")
                      }
                    }
                  } else if (newRadius.length < geofencingPost.radius.length) {
                    setGeofencingPost({ ...geofencingPost, radius: newRadius });
                  }
                  removeValidations()
                  }}
                disabled={props.type === "View_Data"}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="countLimit" className="form-label mb-1">
                Count Limit
              </label>
              <input
                type="text"
                className="form-control"
                name="countLimit"
                max={5000}
                value={geofencingPost.countLimit}
                onChange={(e)=>{
                  const newCountLimit = e.target.value;
                  if (newCountLimit.length < 5) {
                    if(/^\d*$/.test(newCountLimit)){
                      if(newCountLimit <= 5000){
                        setGeofencingPost({ ...geofencingPost, countLimit: newCountLimit });
                      }
                      else{
                        InformationToast("Count Limit is Allowed upto 5000")
                      }
                    }
                  } else if (newCountLimit.length < geofencingPost.countLimit.length) {
                    setGeofencingPost({ ...geofencingPost, countLimit: newCountLimit });
                  }
                  removeValidations()
                  }}
                disabled={props.type === "View_Data"}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-1">
                Message
              </label>
              <textarea className="form-control"
                name="message"
                value={geofencingPost.message.trimStart().replace(/\s{2,}/g, ' ')}
                onChange={handleInputs}
                disabled={props.type === "View_Data"}
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className={`${props.type === "View_Data" ? "d-none" : ""}`}>
          <Button
            variant="primary"
            disabled={loader}
            onClick={formValidation}
          >
            <div className="d-flex align-center">
              {loader ?
                <>
                  <div className="center"><div className="loader"></div></div>
                </>
                : ""}
              <p className="d-inline mx-2 my-0">
                {props.id ? "Update" : "Add"}
              </p>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddBusinessModal