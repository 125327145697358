import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import {useNavigate, useParams } from "react-router-dom";
import SurveyQuestionModal from "../../popupModals/SurveyQuestionModal";
import Loader from "../common/Loader";
import usePutDataServices from "../../hooks/usePutDataServices";


const SurveyAddQuestion = () => {
  const params = useParams();
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(true);
    const [disableToggle, setDisableToggle] = useState({ toggle:false, id:""});
    const [userResponseModal, setUserResponseModal] = useState({
      visibility: false,
      id:""
    });
    const [allSurveyQuestions, setAllSurveyQuestions] = useState([])
    const [surveyQuestionModal, setSurveyQuestionModal] = useState({
        visibility: false,
        type: "",
        id:"",
    });

    const navigate = useNavigate();

    const handleViewResponseModal = (id) => {
      setUserResponseModal({...userResponseModal, visibility:true, id})
    }

    const handleAddEditData = (type,id) =>{
      setSurveyQuestionModal({
        ...surveyQuestionModal,
        visibility: true,
        type,
        id
      });
    }

    const handleStatus =(data)=>{
      setDisableToggle({toggle:true, id:data.id})
      let status = (data.isActive == true) ? false : true ;
      
      UpdateApiServices.updateSurveyQuestionStatus(data.id,status)
      .then((res)=>{
        fetchData(GetApiServices.getAllSurveyQuestions(params.id), setAllSurveyQuestions, setLoader)
      })
      .catch((err)=>{
        console.error("Error while changin survey status : ",err)
      })
      .finally(()=>setDisableToggle({toggle:false, id:data.id}))
    }

      useEffect(()=>{
        if(params.id){
          fetchData(GetApiServices.getAllSurveyQuestions(params.id), setAllSurveyQuestions, setLoader)
        }
      },[surveyQuestionModal])

    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col">
              <h4 className="text-primary fw-bold">Survey Questions</h4>
            </div>

            <div className="col-auto">
              <button
                className="btn btn-outline-secondary"
                onClick={() => navigate("/survey")}
              >
                Back
              </button>
              <button
                    className="btn btn-primary add-button ms-3"    
                    onClick={() => handleAddEditData("Add_Data")}                   
                >   
                    Add Question    
                </button>
            </div>

          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table class="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col">Question</th>
                    {/* <th scope="col-1">Survey Name</th> */}
                    <th scope="col" className="text-center">Token Amount</th>
                    <th scope="col" className="text-center">Answer Type</th>
                    <th scope="col" className="text-center">Status</th>
                    <th scope="col" className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">{
                  loader ? (
                    <tr>
                    <td className="text-center" colSpan={6}>
                      <Loader />
                    </td>
                  </tr>
                  ) :
                 (
                  allSurveyQuestions.length ? (
                    allSurveyQuestions.map((question, index) => (
                    <tr key={index}>
                      <td className="col-3">{question.question}</td>
                      {/* <td>{question.surveyId}</td> */}
                      <td className="text-center col-2">{question.tokenAmount}</td>
                      <td className="text-center">{question.answerType}</td>
                      <td className="text-center">
                          <div className="form-switch">
                              <input className="form-check-input custom-control-input" 
                              type="checkbox" role="switch" 
                              disabled={disableToggle.id === question.id && disableToggle.toggle}
                              checked={question.isActive} 
                              onChange={() => handleStatus(question)}/>
                          </div>
                      </td>
                      <td className="text-center">
                      {/* <button class="btn btn-outline-primary mx-1" onClick={() => handleAddEditData("Edit_Data", `${question.id}`)}>Edit</button> */}
                      <div className="btn-group">
                        <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul className="dropdown-menu py-0" >
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${question.id}`)} >
                              <i className="fa-solid fa-pen-to-square"></i> Edit
                              </li>
                            </div>
                            </ul>
                        </div>
                      </td>
                    </tr>
                    ))
                    ):(
                      <tr>
                        <td className="text-center" colSpan={6}>
                          <h4>No Data Available</h4>
                        </td>
                      </tr>
                    ))
                 }
                </tbody>
              </table>
            </div>
          </div>
          {surveyQuestionModal.visibility ? (
            <SurveyQuestionModal
              type={surveyQuestionModal.type}
              id={surveyQuestionModal.id}
              surveyQuestionModal={surveyQuestionModal.visibility}
              setSurveyQuestionModal={setSurveyQuestionModal}
              surveyId={params.id}
            />
          ) : (
            ""
          )}
        </div>
        </>
      );
}

export default SurveyAddQuestion