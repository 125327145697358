import useAxios from './useAxios'
import { svcconfig } from '../utilities/api-confige'

const useDeleteDataServices = () => {
    const axios = useAxios()

    const deleteWelcomeScreen = (id) => {
        const apiUrl = `/web-admin/welcomeScreen/${id}`
        return axios.delete(apiUrl, id, {headers:svcconfig()})
    }

    const deleteOnboardingScreen = (id) => {
        const apiUrl = `/web-admin/onboardingScreen/${id}`
        return axios.delete(apiUrl, id, {headers:svcconfig()})
    }

    const deleteNews = (newsId) => {
        const apiUrl = `/web-admin/news/${newsId}`
        return axios.delete(apiUrl, {headers:svcconfig()})
    }

    const deleteNotification = (notificationId) => {
        const apiUrl = `/web-admin/notification/${notificationId}`
        return axios.delete(apiUrl, {headers:svcconfig()})
    }
  return{
    deleteWelcomeScreen,
    deleteOnboardingScreen,
    deleteNews,
    deleteNotification
  }
}

export default useDeleteDataServices