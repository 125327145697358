import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../popupModals/DeleteModal";
import OnboardingScreenModal from "../../popupModals/OnboardingScreenModal";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import Loader from "../common/Loader";
import { ErrorToast } from "../../utilities/notification";

const OnboardingScreens = () => {
  const GetApiServices = useGetDataServices();
  const DeleteApiServices = useDeleteDataServices();
  const [onboardingScreens, setOnboardingScreens] = useState([]);
  const [loader, setLoader] = useState(true);
  const [onboardingSetupModal, setOnboardingSetupModal] = useState({
    visibility: false,
    type: "",
    id:""
  });
  const [deleteModal, setDeleteModal] = useState({
    visibility: false,
    deleteItem: "",
    id:""
  });

  const handleAddEditData = (type,id) =>{
    type === "Add_Data" && type === "Edit_Data" ? ErrorToast(`Data are not updating or adding`)
     :setOnboardingSetupModal({
      ...onboardingSetupModal,
      visibility: true,
      type,
      id
    });
  }

  const handleDeleteModal = (data,id) => {
    setDeleteModal({ ...deleteModal, visibility: true, deleteItem: data, id });
  };

  useEffect(() => {
    fetchData(
      GetApiServices.getAllOnboardingScreens(),
      setOnboardingScreens,
      setLoader
    );
  }, []);

  useEffect(() => {
    fetchData(
      GetApiServices.getAllOnboardingScreens(),
      setOnboardingScreens,
      setLoader
    );
  }, [onboardingSetupModal, deleteModal]);

  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">Onboarding Screens</h4>
          </div>
          <div className="col-auto pe-1">
            <button
              className="btn btn-primary add-button col-auto"
              onClick={() => handleAddEditData("Add_Data")}
            >
              Add Screen
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-12 table-responsive fixed-table">
            <table class="table align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className=" table-header-font align-middle">
                  {/* <th scope="col">Screen No.</th> */}
                  <th scope="col" className="col-2">Screen Name</th>
                  <th scope="col" className="col-2">Title</th>
                  <th scope="col" className="text-center">Button Text</th>
                  <th scope="col" className="text-center">Image</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody className="">
                {loader ? (
                  <tr className=" my-5 text-center">
                    {" "}
                    <th colSpan="6" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) : (
                  onboardingScreens.map((curVal, index) => (
                    <tr key={index}>
                    {/* <th scope="row" className="col-2 text-center">{index+1}</th> */}
                      <td className="col-3 ">{curVal.title}</td>
                      <td className="col-3 ">{curVal.screenType}</td>
                      <td className="col text-center">{curVal.buttonText}</td>
                      <td className="text-center">
                        <a href={curVal.imageUrl} target="_blank">
                          <img src={curVal.imageUrl} width="70" height="70" />
                        </a>
                      </td>
                      <td className="text-center">
                      <div class="btn-group">
                        <button type="button" class="dot" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                        </button>
                        <ul class="dropdown-menu py-0" >
                          <li><a class="dropdown-item"  onClick={() =>
                                handleAddEditData("Edit_Data", `${curVal.id}`)
                              }><i class="fa-solid fa-pen-to-square"></i> Edit</a></li>
                          <li><a class="dropdown-item"  onClick={() =>
                                handleDeleteModal(
                                  `screen ${index + 1}`,
                                  `${curVal.id}`
                                )
                              }><i class="fa-solid fa-trash"></i> Delete</a></li>
                        </ul>
                      </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        {onboardingSetupModal.visibility ? (
          <OnboardingScreenModal
            type={onboardingSetupModal.type}
            id={onboardingSetupModal.id}
            onboardingModal={onboardingSetupModal.visibility}
            setOnboardingSetupModal={setOnboardingSetupModal}
          />
        ) : (
          ""
        )}
        {deleteModal.visibility ? (
          <ConfirmationModal
            deleteItem={deleteModal.deleteItem}
            deleteModal={deleteModal.visibility}
            setDeleteModal={setDeleteModal}
            setLoader={setLoader}
            id={deleteModal.id}
            deleteDataApi={() => DeleteApiServices.deleteOnboardingScreen(deleteModal.id)}
            // setNewData={setOnboardingScreens}
            // fetchNewDataApi={() => GetApiServices.getAllOnboardingScreens()}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default OnboardingScreens;
