import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ViewResponseModal from "../../popupModals/ViewResponseModal";
import { ErrorToast } from "../../utilities/notification";
import Loader from "../common/Loader";

const ViewResults = () => {
    const params = useParams();
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const [usersList, setUsersList] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [userResponseModal, setUserResponseModal] = useState({
      visibility: false,
      id:""
    });
    const handleViewResponseModal = (id) => {
      setUserResponseModal({...userResponseModal, visibility:true, id})
    }

    useEffect(()=>{
      GetApiServices.getOnboardingQuestionResult().then((res)=>{
        res.status == 200 && setUsersList(res.data)
      }).catch((err)=>{
        ErrorToast('Something went wrong while getting data')
      })
      .finally(()=> setLoader(false))
    },[])

    useEffect(()=>{
        if(location.pathname === `/onboarding-questions/view-results`){
          console.log("onboarding")
          fetchData(GetApiServices.getOnboardingAnswers(), setUsersList, setLoader)
        }else{
         console.log("survey")
          fetchData(GetApiServices.getSurveyAnsweredUsers(params.id), setUsersList, setLoader)
      }
      return () =>  setUsersList([])
    },[])

    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col">
              <h4 className="text-primary fw-bold">Users List </h4>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-outline-secondary col-auto"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table class="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col">First Name</th>
                    <th scope="col-1">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col" className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {loader ? (
                    <tr className=" my-5 text-center">
                      {" "}
                      <th colSpan="9" className="py-5">
                        <Loader />
                      </th>
                    </tr>
                  ) : (
                    usersList.length ? 
                     ( usersList.map((curVal, index) => (
                        <>
                          <tr className=" my-5 ">
                            <td className="" >{curVal.firstName}</td>
                            <td className="">{curVal.lastName}</td>
                            <td className="">{curVal.email}</td>
                            <td className=" col">
                              <div className="row justify-content-center">
                                <div className="col-auto">
                                  <button
                                    className="btn btn-primary delete-button"
                                    onClick={() =>
                                      handleViewResponseModal(curVal.id)
                                    }
                                  >
                                    View response
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>)) 
                    ): (
                          <th colSpan="9" className="py-5 text-center">
                            <h4 className="text-muted">No Data Available</h4>
                          </th>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
          {userResponseModal.visibility ? (
            <ViewResponseModal
              id={userResponseModal.id}
              userResponseModal={userResponseModal.visibility}
              setUserResponseModal={setUserResponseModal}
            />
          ) : (
            ""
          )}
          {/* <ScrollRestoration /> */}
        </div>
        </>
      );
}

export default ViewResults