import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";

const AddUserModal = (props) => {
    const modelRef = useRef();
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const imageServices = useImageServices();
    const [loader, setLoader] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();

    const handleInputs = (e) => {
        const { name, value } = e.target;
        removeValidations();
      };

      const removeValidations = () => {
        setValidation(true);
        setInvalidFields([]);
      };

    return (
        <div>
          <Modal
            show={props.addUserModal}
            onHide={() => props.setAddUserModal(true)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body ref={modelRef} className="modal-height">
              {validation === false && (
                <div class="alert alert-danger fw-bold" role="alert">
                  Please fill out all the fields !!!
                  {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
                </div>
              )}
              <form>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    // value={setupScreenInputs.title}
                    // onChange={(e) =>{
                    //   setSetupScreenInputs({
                    //     ...setupScreenInputs,
                    //     title: e.target.value?.trimStart(),
                    //   })
                    //   }
                    // }
                    onChange={handleInputs}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    // value={setupScreenInputs.buttonText}
                    // onChange={(e) =>{
                    //   setSetupScreenInputs({
                    //     ...setupScreenInputs,
                    //     buttonText: e.target.value,
                    //   })
                    //   setValidation(true)
                    //   }
                    // }
                    onChange={handleInputs}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Role
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    // value={setupScreenInputs.buttonText}
                    // onChange={(e) =>{
                    //   setSetupScreenInputs({
                    //     ...setupScreenInputs,
                    //     buttonText: e.target.value,
                    //   })
                    //   setValidation(true)
                    //   }
                    // }
                    onChange={handleInputs}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    City 
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    // value={setupScreenInputs.buttonText}
                    // onChange={(e) =>{
                    //   setSetupScreenInputs({
                    //     ...setupScreenInputs,
                    //     buttonText: e.target.value,
                    //   })
                    //   setValidation(true)
                    //   }
                    // }
                    onChange={handleInputs}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    State 
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    // value={setupScreenInputs.buttonText}
                    // onChange={(e) =>{
                    //   setSetupScreenInputs({
                    //     ...setupScreenInputs,
                    //     buttonText: e.target.value,
                    //   })
                    //   setValidation(true)
                    //   }
                    // }
                    onChange={handleInputs}
                  />
                </div>
                
              </form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant="primary"
                // onClick={() => formValidation()}
                disabled={loader}
              >
                {loader ? <Loader /> : ""}
                <p className="d-inline mx-2">
                  {props.type === "Add_Data" ? "Add" : "Update"}
                </p>
              </Button> */}
              <Button
                variant="primary"
                disabled={loader}
              // onClick={() => formValidation()}
              >
              <div className="d-flex align-center">
                {loader ? 
                <>
                <div className="center"><div className="loader"></div></div>
                </>
                : ""}
                <p className="d-inline mx-2 my-0">
                  {props.type === "Add_Data" ? "Add" : "Update"}
                </p>
                </div>
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
}

export default AddUserModal