import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast } from "../utilities/notification";
import { convertHtmlToText, fetchData } from "../utilities/helpers";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import useGetDataServices from "../hooks/useGetData";
import TextEditor from "../components/common/TextEditor";

const TokenModal = (props) => {
    const modelRef = useRef();
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const imageServices = useImageServices();
    const [loader, setLoader] = useState(false);
    const [validation, setValidation] = useState();
    const [invalidFields, setInvalidFields] = useState([]);
    const [tokenData, setTokenData] = useState({
      "type": "",
      "title": "",
      "description": "",
      "tokenAmount": 0
    });
    const [editorState,setEditorState]=useState("");
    const [tokenType, setTokenType] = useState([
      "WELCOME_BONUS",
      "SIGNUP_QUESTION_ANSWERED",
      "SELECT_COMMUNITY",
      "SELECT_BUSINESS_CATEGORY",
      "ADD_VISION",
      "POST_CREATED",
      "CHECKING_CREDIT_SCORE",
      "WALLET_CONNECTED",
      "SURVEY_COMPLETED"]);
      

    // const handleInputChange = (e) => {
    //   const {name, value} = e.target;
    //   setTokenData({...tokenData, 
    //     [name]: value
    //   })
    //   console.log("dsd",tokenData);
    //   // Object.seal(tokenData)
    //   setValidation(false);
    //   setInvalidFields([]);
    // }

    const handleInputChange = (e) => {
      const { name, value } = e.target;
    
      setTokenData((prevTokenData) => ({
        ...prevTokenData,
        [name]: typeof value === "string" ? value.trimStart().replace(/\s{2,}/g, ' ') : value
      }));
      
      setValidation(false);
      setInvalidFields([]);
    };

    const formValidations = () => {
      setLoader(true);
      Object.keys(tokenData).forEach((key) =>{ 
        if(tokenData[key] === "" || tokenData[key] == 0 || isNaN(tokenData["tokenAmount"])){
        key != "description" && invalidFields.push(key);
          if(invalidFields.includes("isActive")){
            const index = invalidFields.indexOf("isActive");
              if (index !== -1) {
                invalidFields.splice(index, 1); // Removes the element at the specified index
              }
          }
        }
      })
      let descriptionField = convertHtmlToText(editorState);
      if(descriptionField === ""){
        setValidation(false)
        invalidFields.push("description")
      }
      if(invalidFields.length > 0){
        setValidation(true);
        setLoader(false);
      }
      if(!invalidFields.length){
        setInvalidFields(false)
      }
      if(!validation && !invalidFields.length){
          AddEditTokenData();
      }
    }

    const AddEditTokenData = () => {
      let data = {
        "type": tokenData.type,
        "title": tokenData.title,
        "description": editorState,
        "tokenAmount": tokenData.tokenAmount
      }
      if(props.id){
        UpdateApiServices.updateToken(props.id, data)
        .then((res)=> {     
          SuccessToast("Token data updated.")
          props.setTokenModal(true);
        })
        .catch((err)=>ErrorToast('Something went wrong.'))
        .finally(()=>setLoader(false))
      }else{
        PostApiServices.AddToken(data)
        .then((res)=> {
          SuccessToast("Token Added successfully.")
          props.setTokenModal(true);
        })
        .catch((err)=> ErrorToast('Something went wrong.'))
        .finally(()=>setLoader(false))
      }
    }

    useEffect(()=>{
      // Object.seal(tokenData)
      if(props.id){
        // fetchData(GetApiServices.getTokenById(props.id), setTokenData,setLoader)
        GetApiServices.getTokenById(props.id).then((res)=>{
          setTokenData(res.data)
          setEditorState(res.data.description)
        })
      }
      //For filtering the token type
      GetApiServices.getAllTokens()
      .then(async (res)=> {
        const allTokenTypes = await res.data.map((data) => data.type);
        const updatedTokenType = tokenType.filter((type) => !allTokenTypes.includes(type));
        setTokenType(props.id ? tokenType : updatedTokenType)
      })
      .catch((err)=> console.log("Error while fetching token data",err))
    },[])

  return (
    <div>
      <Modal
        show={props.tokenModal}
        onHide={() => props.setTokenModal(true)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <h5 className="modal-title">{props.type === "Edit_Data" ? 'Edit Token' : props.type === "Add_Data"  ?'Add Token' : 'View Token'}</h5>
        </Modal.Header>
        <Modal.Body ref={modelRef} className="">
          {validation && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
              {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
            </div>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Token Name
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={tokenData.title}
                onChange={handleInputChange}
                disabled={props.type === "View_Data"}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Token Type
              </label>
              <select
                className="form-select"
                name="type"
                value={tokenData.type}
                onChange={handleInputChange}
                disabled={props.id}
              >
                <option value="" disabled>
                  select token type
                </option>
                {tokenType.map((type)=>(
                  <option value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                class="form-control"
                name="description"
                rows="3"
                value={tokenData.description}
                onChange={handleInputChange}
                disabled={props.type === "View_Data"}>
                </textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Token Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="tokenAmount"
                name="tokenAmount"
                value={tokenData.tokenAmount}
                onChange={(e) => {
                    let tokenValue = e.target.value;
                      if (tokenValue.length < 6) {
                        if (/^\d*$/.test(tokenValue)) {
                          const parsedValue = parseInt(tokenValue, 10);
                          const newTokenAmount = !isNaN(parsedValue) ? Math.abs(parsedValue) : '';
                          setTokenData({ ...tokenData, tokenAmount: newTokenAmount });
                          setValidation(false);
                          setInvalidFields([]);
                        }
                      }
                }}
                disabled={props.type === "View_Data"}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="primary"
            disabled={loader}
            className={props.type === "View_Data" ? 'd-none':""}
            onClick={formValidations}
          >
            {loader ? <Loader /> : ""}
            <p className="d-inline mx-2">
              {props.type === "Add_Data" ? "Add" : "Update"}
            </p>
          </Button> */}
          <Button
           variant="primary"
            disabled={loader}
            className={props.type === "View_Data" ? 'd-none':""}
            onClick={formValidations}
           >
          <div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
            {props.type === "Add_Data" ? "Add" : "Update"}
          </p>
          </div>
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default TokenModal