// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./variable.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table thead.table-header-height {
  z-index: 9;
}
table thead.table-header-height tr.table-header-font th {
  color: var(--font-color);
  border-color: rgba(85, 85, 85, 0.4901960784);
  font-size: 16px;
  line-height: 19px;
  background: #f4f4f4;
  height: 56px;
}

textarea {
  max-height: 100px;
  min-height: 100px;
  resize: none;
  overflow-y: auto;
}

.fixed-table {
  max-height: 65vh;
  overflow-y: auto;
  padding-bottom: 70px;
}

table > tbody > tr:nth-child(even) > td,
table > tbody > tr:nth-child(even) > th {
  background-color: var(--body-background-color);
}

.table > :not(caption) > * > * {
  border-bottom: none !important;
}

.asset-view-result.modal table tbody tr {
  height: 66px;
}

table tbody tr {
  height: 100px;
}
table tbody.mid_height tr {
  height: 72px;
}
table thead tr th.centered-header {
  text-align: center;
  z-index: 1000;
}

table thead.table-header-height:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #9F9F9F;
  position: absolute;
  top: 56px;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/table.scss"],"names":[],"mappings":"AACA;EAGI,UAAA;AADJ;AAEI;EACI,wBAAA;EACA,4CAAA;EAEA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;AADR;;AAKA;EACI,iBAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;AAFJ;;AAKA;EACI,gBAAA;EACA,gBAAA;EACA,oBAAA;AAFJ;;AAKA;;EAEI,8CAAA;AAFJ;;AAKA;EACI,8BAAA;AAFJ;;AAIA;EACI,YAAA;AADJ;;AAII;EACI,aAAA;AADR;AAGI;EACI,YAAA;AADR;AAGI;EACI,kBAAA;EACA,aAAA;AADR;;AAKA;EACI,WAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AAFJ","sourcesContent":["@import url(\"./variable.scss\");\ntable thead.table-header-height {\n    // height: 56px;\n    // border-bottom: 1px solid #9F9F9F;\n    z-index: 9;\n    tr.table-header-font th {\n        color: var(--font-color);\n        border-color: #5555557d;\n        // padding-bottom: 12px;\n        font-size: 16px;\n        line-height: 19px;\n        background: #f4f4f4;\n        height: 56px;\n    }\n}\n\ntextarea {\n    max-height: 100px;\n    min-height: 100px;\n    resize: none;\n    overflow-y: auto;\n}\n\n.fixed-table {\n    max-height: 65vh;\n    overflow-y: auto;\n    padding-bottom: 70px;\n}\n\ntable>tbody>tr:nth-child(even)>td,\ntable>tbody>tr:nth-child(even)>th {\n    background-color: var(--body-background-color);\n}\n\n.table>:not(caption)>*>* {\n    border-bottom: none !important;\n}\n.asset-view-result.modal table tbody tr {\n    height: 66px;\n}\ntable {\n    tbody tr {\n        height: 100px;\n    }\n    tbody.mid_height tr {\n        height: 72px;\n    }\n    thead tr th.centered-header {\n        text-align: center;\n        z-index: 1000;\n    }\n}\n\ntable thead.table-header-height:before {\n    content: '';\n    width: 100%;\n    height: 1px;\n    background: #9F9F9F;\n    position: absolute;\n    top: 56px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
