import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import AddBusinessModal from "../../popupModals/AddBusinessModal";
import usePutDataServices from "../../hooks/usePutDataServices";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import Loader from "../common/Loader";

const Geofencing = () => {
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const [advertisingList, setAdvertisingList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [disableToggle, setDisableToggle] = useState();

  const [addBusinessModal, setAddBusinessModal] = useState({
    visibility: false,
    type: "",
    id: "",
  });

  const handleAddEditData = (type, id) => {
    setAddBusinessModal({
      ...addBusinessModal,
      visibility: true,
      type,
      id,
    });
  };

  const handlePublish = (id) => {
    UpdateApiServices.publishGeofenceBusiness(id).then((res) => {
      SuccessToast("Advertisement Published Successfully");
      fetchAllGeofencing();
    }).catch((err) => {
      ErrorToast('Something Went Wrong');
    });
  };

  const fetchAllGeofencing = async () => {
    await fetchData(GetApiServices.getAllGeofenceBusinesses(), setAdvertisingList, setLoader);
  }

  const handleStatus =  (data) => {
    setDisableToggle(true)
    let status = (data.isActive == true) ? false : true;
    UpdateApiServices.updateGeofenceBusinessStatus(data.id, status)
    .then((res) => {
      fetchAllGeofencing();
      SuccessToast("Status updated successfully.")
    })
    .catch((err) => {
      ErrorToast('Something Went Wrong');
    })
    .finally(()=>setDisableToggle(false))
  }

  useEffect(() => {
    fetchAllGeofencing()
  }, [addBusinessModal]);

  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">Geofencing/Advertisements</h4>
          </div>
          <div className="col-auto pe-1">
            <button
              className="btn btn-primary add-button col-auto"
              onClick={() => handleAddEditData("Add_Data")} >
              Add Business
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 col-md-12 table-responsive fixed-table"
            style={{ overflowX: "auto" }}
          >
            <table class="table table-fit  align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className=" table-header-font align-middle">
                  <th scope="col" className="text-center col-1">Business Name</th>
                  <th scope="col" className=" col-3">Address</th>
                  <th scope="col" className="col-1 text-center">Coordinates</th>
                  <th scope="col" className="text-center col-2">Token Amount</th>
                  <th scope="col" className="text-center col-2">Expiration Date</th>
                  <th scope="col" className="text-center col-2">Count Limit</th>
                  <th scope="col" className="text-center">Status</th>
                  <th scope="col" className="text-center">Publish</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody className="">
                {loader ? (
                  <tr className=" my-5 text-center">
                    <th colSpan="9" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) : advertisingList.length ? (
                  advertisingList.map((curVal, index) => (
                    <tr key={index}>
                      <td className="col">{curVal.qrCode?.businessName}</td>
                      <td className="col-3">{curVal.qrCode?.Address}</td>
                      <td className="col-1 text-center">
                      {curVal.qrCode?.coordinates?.long}, <br/>
                      {curVal.qrCode?.coordinates?.lat} </td>
                      <td className="col-1 text-center">{curVal.qrCode?.tokenAmount}</td>
                      <td className="col-1 text-center">{curVal.qrCode?.discountExpirationDate.split('T')[0].replaceAll('-','/')}</td>
                      <td className="col-1 text-center">{curVal.countLimit}</td>
                      <td className="text-center col-1">
                        <div className="form-switch">
                          <input className="form-check-input custom-control-input" 
                          type="checkbox" 
                          role="switch"
                          onChange={() => handleStatus(curVal)}
                          checked={curVal.isActive}
                          disabled={disableToggle || !curVal.isPublish}
                          />
                        </div>
                      </td>
                      <td className="text-center">
                              <div className="form-switch text-center">
                                <input className="form-check-input" type="checkbox" role="switch" disabled={curVal.isPublish}
                                checked={curVal.isPublish} onClick={()=>handlePublish(curVal.id)}/>
                              </div>
                      </td>
                      <td className="text-center">
                        <div className="btn-group">
                          <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul className="dropdown-menu p-0">
                            <li>
                                 <a className="dropdown-item" 
                                 onClick={() => handleAddEditData("View_Data", `${curVal.id}`)}>
                                  <i className="fa-solid fa-pen-to-square"></i> View 
                                 </a>
                                <a className="dropdown-item"
                                onClick={() => handleAddEditData("Edit_Data", `${curVal.id}`)}>
                                <i className="fa-solid fa-pen-to-square"></i> Edit
                                </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>)
                  )) : (
                  <tr className="my-5 text-center">
                    <th colSpan="8" className="py-5">
                      <span className="text-muted"> <h4>No Data Available</h4> </span>
                    </th>
                  </tr>
                )
                }
              </tbody>
            </table>
          </div>
        </div>
        {addBusinessModal.visibility ? (
          <AddBusinessModal
            addBusinessModal={addBusinessModal}
            setAddBusinessModal={setAddBusinessModal}
            id={addBusinessModal.id}
            type={addBusinessModal.type}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Geofencing;
