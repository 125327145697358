import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../popupModals/DeleteModal";
import AddNewsModal from "../../popupModals/AddNewsModal";
import useGetDataServices from "../../hooks/useGetData";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useNavigate } from "react-router";
import usePutDataServices from "../../hooks/usePutDataServices";
import { fetchData } from "../../utilities/helpers";
import Loader from "../common/Loader";

const NewsAnnouncement = () => {
  const GetApiServices = useGetDataServices();
  const DeleteApiServices = useDeleteDataServices();
  const UpdateApiServices = usePutDataServices();
  const [newsData, setNewsData] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();


  const [addNewsModal, setAddNewsModal] = useState({
    visibility: false,
    type: "",
    id: ""
  });

  const [deleteModal, setDeleteModal] = useState({
    visibility: false,
    deleteItem: "",
    id: ""
  });

  const handleAddEditData = (type, id) => {
    setAddNewsModal({
      ...addNewsModal,
      visibility: true,
      type,
      id
    });
  }

  useEffect(() => {
    fetchData(GetApiServices.getAllNews(), setNewsData, setLoader);
  }, [deleteModal, addNewsModal])

  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">News & Announcements </h4>

          </div>
          <div className="col-auto pe-1">
            <button
              className="btn btn-primary add-button col-auto"
              onClick={() => handleAddEditData("Add_Data")}
            >
              Add News
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-12 table-responsive fixed-table" style={{ overflowX: "auto" }}>
            <table class="table table-fit  align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className=" table-header-font align-middle">
                  <th scope="col" className="col-2">Title</th>
                  <th scope="col" className="col text-center">Type</th>
                  <th scope="col" className="col-4 text-center">News Text</th>
                  <th scope="col" className="col text-center">News Status</th>
                  <th scope="col" className="col text-center">Zip code</th>
                  <th scope="col" className="col text-center"></th>
                </tr>
              </thead>
              <tbody className="">
                {loader ? (
                  <tr className=" my-5 text-center">
                    <th colSpan="6" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) : (
                  newsData?.length ?
                    (newsData?.map((news, index) =>
                      <>
                        <tr key={index}>
                          <td scope="col">{news.title}</td>
                          <td className="col text-center" scope="col">{news.type}</td>
                          <td className="col text-center" scope="col">{news.content}</td>
                          <td className="col text-center">{news.newsStatus}</td>
                          <td className="col text-center">{news.zipCode}</td>
                          <td className="text-center">
                            <div className="btn-group">
                              <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </button>
                              <ul className="dropdown-menu p-0">
                                <li>
                                  <a className="dropdown-item"
                                    onClick={() => handleAddEditData("Edit_Data", `${news.id}`)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i> Edit
                                  </a>
                                </li>
                                <li><a className="dropdown-item"
                                onClick={() => setDeleteModal({visibility: true, id: `${news.id}`})}><i class="fa-solid fa-trash"></i> Delete</a></li>

                              </ul>
                            </div>
                          </td>
                        </tr>
                      </>
                    )

                    ) : (
                      <>
                        <tr className="my-5 text-center">
                          <th colSpan="6" className="py-5">
                            <h4>No Data Available</h4>
                          </th>
                        </tr>
                      </>)
                )
                }

              </tbody>
            </table>
          </div>
        </div>
        {addNewsModal.visibility ? (
          <AddNewsModal
            type={addNewsModal.type}
            id={addNewsModal.id}
            addNewsModal={addNewsModal.visibility}
            setAddNewsModal={setAddNewsModal}
          />
        ) : (
          ""
        )}
        {deleteModal.visibility ? (
          <ConfirmationModal
            deleteItem={deleteModal.deleteItem}
            deleteModal={deleteModal.visibility}
            setDeleteModal={setDeleteModal}
            setLoader={setLoader}
            id={deleteModal.id}
            deleteDataApi={() => DeleteApiServices.deleteNews(deleteModal.id)}
          // setNewData={setUserList}
          // fetchNewDataApi={() => GetApiServices.getAlluserlist()}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default NewsAnnouncement