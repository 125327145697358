// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./variable.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-height {
  height: 70vh;
  overflow-y: auto;
}

.add_community_modal .modal-dialog {
  max-width: 80%;
}
.add_community_modal .modal-dialog table td {
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/popupModal.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,gBAAA;AAAJ;;AAII;EACI,cAAA;AADR;AAGY;EACI,sBAAA;AADhB","sourcesContent":["@import url(\"./variable.scss\");\n\n.modal-height{\n    height: 70vh;\n    overflow-y: auto;\n}\n\n.add_community_modal {\n    .modal-dialog {\n        max-width: 80%;\n        table {\n            td {\n                vertical-align: middle;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
