import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";

const LogoutOnlyRoutes = () => {
  const authContext = useContext(AuthContext);

  return  authContext.loggedIn ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

export default LogoutOnlyRoutes;