import React, { useEffect, useState } from "react";
import AddWalletModal from "../../popupModals/AddWalletModal";
import useGetDataServices from "../../hooks/useGetData";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useNavigate } from "react-router";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";

const WalletScreen = () => {
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const UpdateApiServices = usePutDataServices();
    const [advertisementTypes, setAdvertiseMentTypes] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    
    const [addWalletModal, setAddWalletModal] = useState({
        visibility: false,
        type: "",
        id:""
    });

    const [deleteModal, setDeleteModal] = useState({
      visibility: false,
      deleteItem: "",
      id:""
    });

    const handleAddEditData = (type,id) =>{
        setAddWalletModal({
        ...addWalletModal,
        visibility: true,
        type,
        id
      });
    }

    const handlePublish = (id) =>{
      UpdateApiServices.publishAdvertisementType(id).then((res)=>{
        SuccessToast("AdvertisementType Published Successfully");
        fetchAdvertisementTypesData();
      }).catch((err)=>{
        fetchAdvertisementTypesData();
        ErrorToast('Something Went Wrong');
      });
    };

    const handleStatus =(data)=>{
      let status = (data.isActive == true) ? false : true ;
      UpdateApiServices.updateAdvertisementTypeStatus(data.id,status).then((res)=>{
        fetchAdvertisementTypesData();
      }).catch((err)=>{
        fetchAdvertisementTypesData();
        ErrorToast('Something Went Wrong');
      });
    }

    const fetchAdvertisementTypesData = () =>{
      GetApiServices.getAllAdvertisementTypes().then((res)=>{
        (res.status === 200) && setAdvertiseMentTypes(res.data)      
      }).catch((err)=>{
        ErrorToast('Something went wrong while getting data')
      })
      .finally(()=>setLoader(false))
    }

    useEffect(()=>{
      fetchAdvertisementTypesData()
    },[addWalletModal])

    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col ps-1">
              <h4 className="text-primary fw-bold">Wallet</h4>
            </div>
            <div className="col-auto pe-1">
                <button
                className="btn btn-primary add-button col-auto"
                onClick={() => handleAddEditData("Add_Data")}
                >
                Add Advertise Type
                </button>
            </div>
            
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table className="table table-fit align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col" className="col-3">Advertise Types</th>
                    <th scope="col" className="text-center col-4">Status</th>
                    <th scope="col" className="text-center col-4">Publish</th>
                    <th scope="col" className="text-center col-4"></th>
                  </tr>
                </thead>
                <tbody className="mid_height">
                {loader ? (
                    <tr className=" my-5 text-center">
                      {" "}
                      <th colSpan="9" className="py-5">
                        <Loader />
                      </th>
                    </tr>
                  ) : (
                    advertisementTypes.length ? (
                    advertisementTypes.map((item, index) => (
                      <tr key={index}>                      
                        <td className="col-3">{item.name}</td>
                        <td className="col-4 text-center">
                           <div className="form-switch text-center">
                           <input className="form-check-input" type="checkbox" role="switch" disabled={!item.isPublish}
                            onChange={()=>handleStatus(item)}  
                            checked={item.isActive} />
                           </div>
                         </td>     
                         <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={item.isPublish}
                            checked={item.isPublish} onClick={()=>handlePublish(item.id)}
                           />
                          </div>
                        </td>                 
                         <td className="text-center">
                           <div className="btn-group">
                           <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                           <i className="fa-solid fa-ellipsis-vertical"></i>
                           </button>
                           <ul className="dropdown-menu py-0">
                           {!item.isPublish &&
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${item.id}`) } >
                              <i className="fa-solid fa-pen-to-square"></i> Edit
                              </li>
                            </div>}
                            <div className="">
                              <li className="dropdown-item" 
                                 onClick={() => navigate(`/wallet/advertise/${item.name}/${item.id}`)}>
                              <i className="fa-solid fa-upload"></i> Add Advertise
                              </li>
                            </div> 
                           </ul>
                         </div>
                         </td>
                       </tr>
                    ))
                    ) 
                    : (<>
                        <tr className="my-5 text-center">
                      <th colSpan="3" className="py-5">
                        <h4 className="text-muted">No Data Available</h4>
                      </th>
                    </tr>
                    </>)
                  )}
                   
                </tbody>
              </table>
            </div>
          </div>
          {addWalletModal.visibility ? (
            <AddWalletModal
            addWalletModal={addWalletModal}
            setAddWalletModal={setAddWalletModal}
            id={addWalletModal.id}
            type={addWalletModal.type}
            />
            ) : (
            ""
            )}
        </div>
        </>
      );
}

export default WalletScreen