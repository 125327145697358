import React, { useEffect, useState } from "react";
import WelcomeScreenModal from "../../popupModals/WelcomeScreenModal";
import "bootstrap/dist/css/bootstrap.css";
import ConfirmationModal from "../../popupModals/DeleteModal";
import useGetDataServices from "../../hooks/useGetData";
import { InformationToast } from "../../utilities/notification";
import { fetchData } from "../../utilities/helpers";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import Loader from "../common/Loader";
import { Link } from "react-router-dom";

const AdminWelcomScreen = () => {
  const GetApiServices = useGetDataServices();
  const deleteWelcomeScreen = useDeleteDataServices();
  const [allWelcomeScreens, setAllWelcomeScreens] = useState([]);
  const [loader, setLoader] = useState(true);
  const [welcomeScreenLimit, setWelcomeScreenLimit] = useState(false);
  const [welcomeSetupModal, setWelcomeSetupModal] = useState({
    visibility: false,
    type:"",
    id:""
  });
  const [deleteModal, setDeleteModal] = useState({
    visibility:false,
    deleteItem:"",
    id:""
  })

  const handleAddEditData = (type, id) =>{
    type === "Add_Data" && type === "Edit_Data" && welcomeScreenLimit ? InformationToast("You cannot add more than 5 screens.") : setWelcomeSetupModal({ ...welcomeSetupModal, visibility: true ,type, id});
  }
  const handleDeleteModal = (data,id) => {
    setDeleteModal({...deleteModal, visibility:true, deleteItem:data,id})
  }

  // async function fetchData() {
  //     try {
  //       const response = await GetApiServices.getAllWelcomeScreens();
  //       setAllWelcomeScreens(response.data);
  //     } catch (error) {
  //       ErrorToast(error);
  //     } finally {
  //       setLoader(false);
  //     }
  //   }
  useEffect(()=>{
    fetchData(GetApiServices.getAllWelcomeScreens(), setAllWelcomeScreens,setLoader);
    if(allWelcomeScreens.length >= 5){
      setWelcomeScreenLimit(true)
    }else{
      setWelcomeScreenLimit(false)
    }
  },[])

  useEffect(()=>{
    fetchData(GetApiServices.getAllWelcomeScreens(), setAllWelcomeScreens,setLoader);
  },[welcomeSetupModal, deleteModal])
  
  useEffect(()=>{
    if(allWelcomeScreens.length >= 5){
      setWelcomeScreenLimit(true)
    }else{
      setWelcomeScreenLimit(false)
    }
  },[allWelcomeScreens])

  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
        <div className="col ps-1">
          <h4 className="text-primary fw-bold m-0">Welcome Screen</h4>
        </div>
          <div className="col-auto pe-1">
            <button
              className="btn btn-primary add-button col-auto"
              onClick={() => handleAddEditData("Add_Data")}
            >
              Add Screen
            </button>
          </div>
        </div>
        <div className="row">
        <div className="col-12 col-md-12 table-responsive fixed-table">
          <table className="table align-middle">
            <thead className="table-light sticky-position table-header-height">
              <tr className=" table-header-font align-middle">
                <th scope="col" className="col-1 text-center">Slider No.</th>
                <th scope="col" className="">Title</th>
                <th scope="col" className="">Description</th>
                <th scope="col" className="text-center">Screen Images</th>
                <th scope="col" className="text-center">Button Text</th>
                <th scope="col" className="text-center"></th>                                
              </tr>
            </thead>
            <tbody className="">
                        {loader ? (<tr className=" my-5 text-center"> <th colSpan='6' className="py-5"><Loader /></th></tr>) :  ( 
                          allWelcomeScreens.map((curVal, index)=>(
              <tr key={index}>
                <th scope="row" className="col-1 text-center">{index+1}</th>
                <td className="col-2">{curVal.title}</td>
                <td className="col-4">{curVal.content}</td>              
                <td className="text-center">
                  <Link to={curVal.imageUrl} target="_blank">
                    <img src={curVal.imageUrl} width="70" height="70"/>
                  </Link>
                </td>
                <td className="col text-center">{curVal.buttonText}</td>
                <td className="text-center">
                <div className="btn-group">
                <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                 <i className="fa-solid fa-ellipsis-vertical"></i>
                </button>
                  <ul className="dropdown-menu py-0 px-0">
                  <li><a className="dropdown-item"  onClick={() => handleAddEditData("Edit_Data" , `${curVal.id}`)}><i className="fa-solid fa-pen-to-square"></i> Edit</a></li>
                  <li><a className="dropdown-item"  onClick={()=> handleDeleteModal(`screen ${index+1}`, `${curVal.id}`)}><i className="fa-solid fa-trash"></i> Delete</a></li>
                  </ul>
                </div>
                </td>
              </tr>
            ))
            )}
            </tbody>  
          </table>
        </div>
        </div>
        {welcomeSetupModal.visibility ? (
          <WelcomeScreenModal
            type={welcomeSetupModal.type}
            welcomeSetupModal={welcomeSetupModal.visibility}
            setWelcomeSetupModal={setWelcomeSetupModal}
            id={welcomeSetupModal.id}
          />
        ) : (
          ""
        )}
        {deleteModal.visibility ? (
          <ConfirmationModal
          deleteItem={deleteModal.deleteItem}
            deleteModal={deleteModal.visibility}
            setDeleteModal={setDeleteModal}
            setLoader={setLoader}
            id={deleteModal.id}
            deleteDataApi={() => deleteWelcomeScreen.deleteWelcomeScreen(deleteModal.id)}
            // fetchNewDataApi={() => GetApiServices.getAllWelcomeScreens()}
            // setNewData={setAllWelcomeScreens}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );

  
};

export default AdminWelcomScreen;
