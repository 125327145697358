import React from 'react'

const Loader = () => {
  return (
    <div class="spinner-border text-secondary spinner-border-sm" role="status" style={{'height':'40px','width':'40px'}}>
        {/* <CircularProgress color="secondary" size='100rem' /> */}
    </div>
  )
}

export default Loader;