import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import usePutDataServices from "../../hooks/usePutDataServices";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import Loader from "../common/Loader";
import { useNavigate } from "react-router";
import AssetViewResultModal from "../../popupModals/AssetViewResultModal";

const UserAsset = () => {
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const [categories, setCategories] = useState([]);
  const [userassetdata, setuserassetdata]=useState([])
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [assetViewResultModal, setAssetViewResultModal] = useState({
    visibility: false,
    type: "",
    id: "",
  });

  const handleAddEditData = (type, id) => {
    setAssetViewResultModal({
      ...assetViewResultModal,
      visibility: true,
      type,
      id,
    });
  };

  const handlePublish = (id) =>{
    UpdateApiServices.publishCategory(id).then((res)=>{
      SuccessToast("Category Published Successfully");
      fetchCategoryData();
    }).catch((err)=>{
      fetchCategoryData();
      ErrorToast('Something Went Wrong');
    });
  };

  const fetchCategoryData =()=>{
    fetchData(
      GetApiServices.getAssetUserList(),
      setuserassetdata,
      setLoader );
  }

  useEffect(() => {
    fetchCategoryData();
  }, []);



  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">User Assets</h4>
          </div>
          
            <div className="col-auto pe-1">
                <button class="btn btn-outline-secondary col-auto" onClick={() => navigate("/assets")}>Back</button>
            </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 col-md-12 table-responsive fixed-table"
            style={{ overflowX: "auto" }}
          >
            <table class="table table-fit align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className=" table-header-font align-middle">
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col-2" className="">Email</th>
                  <th scope="col-2" className="text-center">Action</th>
                </tr>
              </thead>
              <tbody className="">
              {loader ? (
                  <tr className=" my-5 text-center">
                    <th colSpan="9" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) : userassetdata.length ? (
                  userassetdata.map((curVal, index) => (
                  <tr key={index}>                    
                    <td className="col">{curVal.firstName}</td>
                    <td className="col">{curVal.lastName}</td>
                    <td className="col-2">{curVal.email}</td>
                    <td className="text-center col-2">
                      <div className="row justify-content-center">
                        <div className="col-auto px-2">
                            <button
                                className="btn btn-primary edit-button" onClick={() => handleAddEditData("Add_Data",curVal.id)}>
                                View Result
                            </button>
                        </div>                                                
                        
                      </div>
                    </td>
                  </tr>
                ))
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
        {assetViewResultModal.visibility ? (
          <AssetViewResultModal
            id={assetViewResultModal.id}
            assetViewResultModal={assetViewResultModal.visibility}
            setAssetViewResultModal={setAssetViewResultModal}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default UserAsset;
