export const svcconfig = () => {
    return {
    Pragma: "no-cache",
    "Content-Type": "application/json",
}
}
export const fileUploadingConfig = () => {
    return {
        Pragma: "no-cache",
    "Content-Type": "multipart/form-data",
}
}