import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({
  loggedIn: false,
  setLoggedIn : ()=>{},
  userData : null,
  setUserData : ()=>{}
});

export const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState();

    useEffect(()=>{
        let user = localStorage.getItem('user')
        if(user){
            setUserData(JSON.parse(user));
            setLoggedIn(true)
        }else{
            setLoggedIn(false)
        }
    },[])


  return (
    <AuthContext.Provider value={{ loggedIn,setLoggedIn,userData,setUserData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;