import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";

const AddNewsModal = (props) => {
    const modelRef = useRef();
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(false);
    const [newsInfo, setNewsInfo] = useState({
        title: "",
        content: "",
        type: "",
        newsStatus: "",
        zipCode: ""
    });
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();
    const zipCodeRegEx = /^[0-9]{4}/;

    const handleInputs = (e, id) => {
      const { name, value } = e.target;
      setNewsInfo({ ...newsInfo, 
        [name]: typeof value === "string" ? 
        value.trimStart().replace(/[^a-z\s]/ig, '').replace(/\s{2,}/g, ' ') : value
      });
      removeValidations();
      setLoader(false)
    };


    const handleAddUpdateData =(id)=>{
      let data = {
        "title": newsInfo.title,
        "content": newsInfo.content,
        "type": newsInfo.type,
        "newsStatus": newsInfo.newsStatus,
        "zipCode": newsInfo.zipCode.toString()
      }
      if(props.id){
        UpdateApiServices.updateNews(data, props.id).then((res)=>{
          props.setAddNewsModal(false)
          res.status == 200 && SuccessToast("News Updated Sucessfully")
        })
        .catch((err)=>{
          ErrorToast('Something went wrong while Updating data')
        })
        .finally(() => setLoader(false))
      }else{
        PostApiServices.addNews(data).then((res)=>{
          props.setAddNewsModal(false)
          res.status == 201 && SuccessToast("News Added Sucessfully")
        })
        .catch((err)=>{
          ErrorToast('Something went wrong while Adding data')
        })
        .finally(() => setLoader(false))
      }
    }

    const formValidation = () => {
      setLoader(true)
      for (const key of Object.keys(newsInfo)) {
        if (newsInfo[key] === "") {
          // setInvalidFields((prevVal) => [...prevVal, ...invalidFields, key]);
          invalidFields.push(key)
          setValidation(false);
          setLoader(false);
          modelRef.current.scrollTop = 0;
          setLoader(false);
        }
      }
      if (validation && !invalidFields.length) {
        handleAddUpdateData();
      }
    };

    const removeValidations = () => {
      setValidation(true);
      setInvalidFields([]);
    };

    useEffect(()=>{
      if(props.type === "Edit_Data" || props.type === "View_Data"){
      GetApiServices.getNewsById(props.id).then((res)=>{
        const data = res.data;
        setNewsInfo({
          title: data.title,
          content: data.content,
          type: data.type,
          newsStatus: data.newsStatus,
          zipCode: data.zipCode
        })
      }).catch((err)=>{
        ErrorToast('Something went wrong while getting data')
      })
    } },[])


  return (
    <div>
    <Modal
      show={props.addNewsModal}
      onHide={() => props.setAddNewsModal(true)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.type === "Add_Data" ? 'Add' : props.type === "Edit_Data" ? "Edit" : "View"} News</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modelRef} className="modal-height">
        {validation === false && (
          <div class="alert alert-danger fw-bold" role="alert">
            Please fill out all the fields !!!
            {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
          </div>
        )}
        <form>
          <div className="mb-4">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              disabled = {props.type === "View_Data"}
              value={newsInfo.title}
              onChange={handleInputs}
            />
          </div>

          <div className="mb-4">
              <label htmlFor="title" className="form-label">
                 Type
              </label>
              <select
                className="form-select" value={newsInfo.type}
                onChange={handleInputs}
                disabled = {props.type === "View_Data"}
                name="type" id = "type">
                  <option value="" disabled selected>Select News Type</option>
                  <option value="NORMAL">Normal</option>
                  <option value="ANNOUNCEMENT">Announcement</option>
                  <option value="MEETING">Meeting</option>
              </select>
          </div>
            <div className="mb-4">
                <label htmlFor="image" className="form-label">
                    Content
                </label>
                <textarea 
                    className="form-control" 
                    value={newsInfo.content}
                    onChange={(e)=>{
                         const { value } = e.target;
                         const cleanedValue = value.trimStart().replace(/\s{2,}/g, ' ');
                         setNewsInfo({ ...newsInfo, content: cleanedValue })
                         removeValidations();
                         setLoader(false)                 
                    }}
                    disabled = {props.type === "View_Data"}
                    name="content"
                    id = "content">
                </textarea>
            </div>
            

          <div className="mb-4">            
            <label htmlFor="title" className="form-label">
              News Status
            </label>
                <select
                    className="form-select" value={newsInfo.newsStatus}
                    onChange={handleInputs}
                    disabled = {props.type === "View_Data"}
                    name="newsStatus" id = "newsStatus">
                    <option value="" disabled selected>Select News Status</option>
                    <option value="LATEST">Latest</option>
                    <option value="NORMAL">Normal</option>
                    <option value="OLD">Old</option>
                </select>
             </div>
              <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                  Zip code
                  </label>
                  <input
                  type="text"
                  className="form-control"
                  name="zipCode"
                  min={0}
                  disabled = {props.type === "View_Data"}
                  value={newsInfo.zipCode}
                  onChange={(e) => {
                    let ZipcodeValue = e.target.value;
                      if (ZipcodeValue.length <= 5) {
                        if (/^\d*$/.test(ZipcodeValue)) {
                          const parsedValue = parseInt(ZipcodeValue, 10);
                          const newZipCode = !isNaN(parsedValue) ? Math.abs(parsedValue) : '';
                          setNewsInfo({ ...newsInfo, zipCode: newZipCode });
                          removeValidations();
                          setLoader(false)
                          }
                        }
                  }}    
                  />
              </div>
        </form>
      </Modal.Body>
      {!(props.type === "View_Data") &&
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={loader}
          onClick={formValidation}
        >
        <div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
            {props.type === "Add_Data" ? "Add" : "Update"}
          </p>
          </div>
        </Button>
      </Modal.Footer>}
    </Modal>
  </div>
  )
}

export default AddNewsModal