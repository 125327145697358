import React from 'react'
import { useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import usePostDataServices from '../hooks/usePostData'
import {SuccessToast,ErrorToast} from '../utilities/notification'
import Loader from '../components/common/Loader'
import { useState } from 'react';
const CreateUserModal = (props) => {
    const modelRef = useRef();
    const [loader, setLoader] = useState(false);
    const [userData,setUserData]=useState({
      firstName : "",
      lastName : "",
      email : "",
      password : "",
      zipCode : ""
    });
    const PostApiServices = usePostDataServices()
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();
    
    const handleInputs = (e) => {
      const { name, value } = e.target;
      setUserData({ ...userData, [name]:value.trimStart().replace(/[^a-zA-Z0-9\s]/ig, '').replace(/\s{2,}/g, ' ')});
      removeValidations();
    };

  const formValidation = () => {
    for (const key of Object.keys(userData)) {
      if (userData[key] === "") {
        invalidFields.push(key)
        setValidation(false);
        modelRef.current.scrollTop = 0;
      }
      if (key === 'email') {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z][a-zA-Z.-]*[a-zA-Z]$/;

        if (!emailRegex.test(userData.email)) {
            ErrorToast('Invalid email format');
            setValidation(false);
            setLoader(false);
            modelRef.current.scrollTop = 0;
            return;
        }
    }
    }
    if (validation && !invalidFields.length) {
      addUser();
    }
  };
    const removeValidations = () => {
          setValidation(true);
          setInvalidFields([]);
        };
  
  const addUser = () => {
    let createUserdata = {
        firstName : userData.firstName,
        lastName : userData.lastName,
        email : userData.email,
        password : userData.password,
        zipCode : userData.zipCode.toString()
    }
  setLoader(true)
    PostApiServices.addUser(createUserdata).then((response)=>{
      if(response.status == 200){
        SuccessToast("User is Created Succesfully")
        props.setCreateUserModal(false)
      }
    })
    .catch((err)=>{
      ErrorToast("Something went wrong while creating user !!!")
    }).finally(()=>setLoader(false))

  }
  return (
    <div>
        <Modal
            show={props.createUserModal}
            onHide={() => props.setCreateUserModal(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create User</Modal.Title>
            </Modal.Header>
            <Modal.Body ref={modelRef} className="modal-height">
              {validation === false && (
                <div class="alert alert-danger fw-bold" role="alert">
                  Please fill out all the fields !!!
                  {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
                </div>
              )}
              <form>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={userData.firstName}    
                    maxLength={20}
                    onChange={handleInputs}
                  />
                </div>
                <div className="mb-4">
                    <label htmlFor="title" className="form-label">
                        Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"     
                      value={userData.lastName}               
                      maxLength={20}
                      onChange={handleInputs}
                    />
                </div>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"       
                    value={userData.email}             
                    maxLength={30}
                    onChange={(e)=>{
                      const { value } = e.target;
                      const cleanedValue = value.trim().replace(/\s{2,}/g, ' ');
                      setUserData({ ...userData, email: cleanedValue });
                      removeValidations();
                    }}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                    Passsword
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    name="password"       
                    value={userData.password}             
                    maxLength={20}
                    onChange={handleInputs}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                    zipCode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zipCode"
                    name="zipCode"       
                    value={userData.zipCode}             
                    onChange={(e) => {
                      let ZipcodeValue = e.target.value;
                        if (ZipcodeValue.length <= 5) {
                          if (/^\d*$/.test(ZipcodeValue)) {
                            const parsedValue = parseInt(ZipcodeValue, 10);
                            const newZipCode = !isNaN(parsedValue) ? Math.abs(parsedValue) : '';
                            setUserData({ ...userData, zipCode: newZipCode });
                            removeValidations();
                            }
                          }
                    }}   
                  />
                </div>
               
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => formValidation()}
                disabled={loader}
              >
                <div className="d-flex align-center">
                {loader ? 
                  <>
                  <div className="center"><div className="loader"></div></div>
                  </>
                  : ""
                }
                <p className="d-inline mx-2 my-0">
                  Create User
                </p>
              </div>
              </Button>
            </Modal.Footer>
          </Modal>
    </div>
  )
}

export default CreateUserModal