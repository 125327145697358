// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./variable.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-screen {
  height: 100vh;
  width: 100vw;
}
@media (max-width: 576px) {
  .login-screen {
    height: 100%;
    width: 100%;
  }
}
.login-screen .login-form {
  background-color: var(--white-color);
  border: 1px solid var(--font-color);
  width: 525px;
}
@media (max-width: 576px) {
  .login-screen .login-form {
    width: 100%;
  }
}
.login-screen .login-form label.form-label {
  font-size: 17px;
  line-height: 24px;
  color: var(--font-color);
  font-weight: 500;
}
.login-screen .login-form .card-header {
  background-color: var(--primary-color);
}
.login-screen .login-form a {
  font-size: 17px;
  font-weight: 500;
  color: var(--primary-color);
}
.login-screen .login-form button {
  letter-spacing: 0.6px;
  font-size: 15px;
  font-weight: 600;
}
.login-screen .login-form button:active {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.login-screen .login-form button:focus-visible {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/login-form.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,YAAA;AAAJ;AACI;EAHJ;IAIQ,YAAA;IACA,WAAA;EAEN;AACF;AADI;EACI,oCAAA;EACA,mCAAA;EACA,YAAA;AAGR;AAFQ;EAJJ;IAKQ,WAAA;EAKV;AACF;AAFY;EACI,eAAA;EACA,iBAAA;EACA,wBAAA;EACA,gBAAA;AAIhB;AADQ;EACI,sCAAA;AAGZ;AADQ;EACI,eAAA;EACA,gBAAA;EACA,2BAAA;AAGZ;AADQ;EACI,qBAAA;EACA,eAAA;EACA,gBAAA;AAGZ;AAFY;EACI,sCAAA;EACA,sCAAA;AAIhB;AAFY;EACI,sCAAA;EACA,sCAAA;EACA,gBAAA;AAIhB","sourcesContent":["@import url(\"./variable.scss\");\n\n.login-screen {\n    height: 100vh;\n    width: 100vw;\n    @media (max-width:576px) {\n        height: 100%;\n        width: 100%;\n    }\n    .login-form {\n        background-color: var(--white-color);\n        border: 1px solid var(--font-color);\n        width: 525px;\n        @media (max-width:576px) {\n            width: 100%;\n        }\n\n        label {\n            &.form-label {\n                font-size: 17px;\n                line-height: 24px;\n                color: var(--font-color);\n                font-weight: 500;\n            }\n        }\n        .card-header {\n            background-color: var(--primary-color);\n        }\n        a {\n            font-size: 17px;\n            font-weight: 500;\n            color: var(--primary-color);;\n        }\n        button {\n            letter-spacing: 0.6px;\n            font-size: 15px;\n            font-weight: 600;\n            &:active {\n                background-color: var(--primary-color);\n                border: 1px solid var(--primary-color);\n            }\n            &:focus-visible {\n                background-color: var(--primary-color);\n                border: 1px solid var(--primary-color);\n                box-shadow: none;\n            }\n        }\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
