import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const CommunityPostModal = (props) => {
    const modelRef = useRef();
    const AuthData = useContext(AuthContext);
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();
    const [postData, setPostData] = useState({
      "title": "",
      "content": "",
      "imageUrl": "",
      "postType": "COMMUNITY",
      "userId": AuthData.userData.id,
      "communityId": props.communityID
    });

    const handleInputChange = (e) => {
       const {name, value} = e.target;
       setPostData({...postData, 
        [name]:typeof value === "string" ? 
       value.trimStart().replace(/[^a-z\s]/ig, '').replace(/\s{2,}/g, ' ') : value})
       removeValidations()
       setLoader(false)
    }

    const formValidation = async() =>{
      setLoader(true)
      setInvalidFields([])
  
      for(const key of Object.keys(postData)){
        if(postData[key] === ""){
          if(key !== "imageUrl"){
            invalidFields.push(key)
            setLoader(false);
            setValidation(false);
            modelRef.current.scrollTop = 0;
          }
        }
      }
      console.log(validation)
      console.log(invalidFields)
      if(validation && !invalidFields.length){
        AddCommunityPost();
      }
    }

    const removeValidations = () => {
      setValidation(true);
      setInvalidFields([]);
    };

    const AddCommunityPost = () => {
      setLoader(true)
      PostApiServices.AddCommunityPosts(postData)
      .then((res)=>{
        if(res.status == 201){
          SuccessToast("Community post added successfully")
          props.setCommunityPostModal(false)
        }}
      )
      .catch((err)=>{
        ErrorToast("Something went wrong while adding community post !!!")
        console.error("Error on adding community post :",err)})
      .finally(() => setLoader(false))
    }

  return (
    <div>
    <Modal
    show={props.communityPostModal}
    onHide={() => props.setCommunityPostModal(false)}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
    <h5 className="modal-title">{props.id ? "Update" : "Add"} Community Post</h5>
    </Modal.Header>
    <Modal.Body ref={modelRef} >
          {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
              {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
            </div>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={postData.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Description
              </label>
              <textarea
            className="form-control"
            name="content"
            rows={6}
            value={postData.content}
            onChange={handleInputChange}
            ></textarea>
            </div>                     
          </form>
        </Modal.Body>
    <Modal.Footer>
      {/* <Button variant="primary" 
      disabled={loader}
      onClick={AddCommunityPost}
      >
        {loader ? <Loader /> : ""}
        Add
      </Button> */}
      <Button
          variant="primary"
          disabled={loader}
          onClick={formValidation}
        ><div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
          Add
          </p>
          </div>
        </Button>
    </Modal.Footer>
  </Modal>
</div>
  )
}

export default CommunityPostModal