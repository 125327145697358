import React from "react";
import { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  SuccessToast,
  ErrorToast,
  InformationToast,
} from "../utilities/notification";
import Loader from "../components/common/Loader";
import { useState } from "react";
import usePutDataServices from "../hooks/usePutDataServices";

const ChangePasswordModal = (props) => {
  const modelRef = useRef();
  const [loader, setLoader] = useState(false);
  const UpdateApiServices = usePutDataServices();
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState();
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState();
  const [validationMessage, setValidationMessage] = useState("");

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value.trim() });
    removeValidations();
  };

  const formValidation = () => {
    for (const key of Object.keys(passwordData)) {
      if (passwordData[key] === "" || passwordData[key].trim() === "") {
        invalidFields.push(key);
        setValidation(false);
        modelRef.current.scrollTop = 0;
      }
    }
    if (invalidFields.length) {
      setValidation(false);
      setValidationMessage("Please fill out all the fields !!!");
    } else if (passwordData.oldPassword === passwordData.confirmPassword) {
      setValidation(false);
      setValidationMessage(
        "Current password and Confirm password should not be the same"
      );
    } else if (
      passwordData.confirmPassword.length >= 8 &&
      passwordData.newPassword.length >= 8 &&
      passwordData.confirmPassword !== passwordData.newPassword
    ) {
      setValidation(false);
      setValidationMessage(
        "New password and Confirm password must be the same"
      );
    } else if (
      !invalidFields.length &&
      (passwordData.oldPassword.length < 8 ||
        passwordData.confirmPassword.length < 8 ||
        passwordData.newPassword.length < 8)
    ) {
      setValidation(false);
      setValidationMessage(
        "Password must be longer than or equal to 8 characters"
      );
    } else {
      changePassword();
    }
  };

  const removeValidations = () => {
    setValidation(true);
    setValidationMessage("");
    setInvalidFields([]);
  };

  const changePassword = () => {
    let changePasswordData = {
      oldPassword: passwordData.oldPassword,
      newPassword: passwordData.newPassword,
    };
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.id) {
      setLoader(true);
      UpdateApiServices.updateUserPassword(changePasswordData, user.id)
        .then((response) => {
          if (response?.data?.status == "success") {
            SuccessToast("Password is Updated Succesfully");
            props.setChangePasswordModal(false);
          } else {
            ErrorToast(response?.data?.message);
          }
        })
        .catch((err) => {
          ErrorToast("Something went wrong while Updating password !!!");
        })
        .finally(() => setLoader(false));
    }
  };

  return (
    <div>
      <Modal
        show={props.changePasswordModal}
        onHide={() => props.setChangePasswordModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modelRef} className="">
          {validationMessage.length ? (
            <div class="alert alert-danger fw-bold" role="alert">
              {validationMessage}
              {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
            </div>
          ) : (
            <></>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
              Current Password
              </label>
              <input
                type="text"
                className="form-control"
                id="oldPassword"
                name="oldPassword"
                value={passwordData.oldPassword}
                onChange={handleInputs}
                maxLength={20}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                New Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="newPassword"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handleInputs}
                maxLength={20}
              />
              <span
                toggle="#password-field"
                className={
                  showPassword
                    ? "fa fa-fw fa-eye-slash field-icon toggle-password"
                    : "fa fa-fw fa-eye field-icon toggle-password"
                }
                onClick={() => setShowPassword(!showPassword)}
              ></span>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Confirm Password
              </label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                maxLength={20}
                onChange={handleInputs}
              />
              <span
                toggle="#password-field"
                className={
                  showConfirmPassword
                    ? "fa fa-fw fa-eye-slash field-icon toggle-password"
                    : "fa fa-fw fa-eye field-icon toggle-password"
                }
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              ></span>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => formValidation()}
            disabled={loader}
          >
            <div className="d-flex align-center">
              {loader ? (
                <>
                  <div className="center">
                    <div className="loader"></div>
                  </div>
                </>
              ) : (
                ""
              )}
              <p className="d-inline mx-2 my-0">Change Password</p>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
