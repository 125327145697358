import React, { useState } from "react";
import AddQrcodeModal from "../../popupModals/AddQrcodeModal";
import useGetDataServices from "../../hooks/useGetData";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useNavigate } from "react-router";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";
import { useEffect } from "react";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
const QrCode = () => {
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const UpdateApiServices = usePutDataServices();
    const [QRCodeBusinessData, setQRCodeBusinessData] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    const [qrcodeModal, setQrcodeModal] = useState({
      visibility: false,
      type: "",
      id:""
    });
    
    const handleAddEditData = (type,id) => {
      setQrcodeModal({
        ...qrcodeModal,
        visibility: true,
        type,
        id
      });
    }

    const handleStatus =(data)=>{
      let status = (data.isActive == true) ? false : true ;
      UpdateApiServices.updateQRCodeStatus(data.id,status).then((res)=>{
        fetchQRCodeBusinessData();
      }).catch((err)=>{
        fetchQRCodeBusinessData();
        ErrorToast('Something Went Wrong');
      });
    }

    const handlePublish = (id) =>{
      UpdateApiServices.publishQRCode(id).then((res)=>{
        SuccessToast("QR Code Published Successfully");
        fetchQRCodeBusinessData();
      }).catch((err)=>{
        fetchQRCodeBusinessData();
        ErrorToast('Something Went Wrong');
      });
    };

    const fetchQRCodeBusinessData = () => {
      GetApiServices.getAllQRCodeBusiness().then((res)=>{
        if(res.status == 200){
          setQRCodeBusinessData(res.data)
        }
      }).catch((err)=>{
        ErrorToast("No Data", err)
      }).finally(()=>setLoader(false))
    }

    useEffect(()=>{
      fetchQRCodeBusinessData()
    },[qrcodeModal])

    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col ps-1">
              <h4 className="text-primary fw-bold">Affiliate QR Code Generator</h4>
            </div>
            <div className="col-auto pe-1">              
              <button
                className="btn btn-primary add-button col-auto"
                onClick={() => handleAddEditData("Add_Data")}>
                   Add Affiliate QR Code
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table class="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col" className="col text-center">QR Code</th>
                    <th scope="col" className="col text-center">Business Name</th>
                    <th scope="col" className="col text-center">Business Owner</th>
                    <th scope="col" className="col-3">Address</th>
                    <th scope="col" className="col text-center">Logo</th>
                    <th scope="col" className="col-2 text-center">Mobile Number</th>
                    <th scope="col" className="col-1 text-center">Status</th>
                    <th scope="col" className="text-center">Publish</th>
                    <th scope="col" className="text-center"></th>
                  </tr>
                </thead>
                <tbody className="">
                  {
                    loader ? (
                      <tr className="my-5 text-center">
                        <th colSpan="9" className="py-5"><Loader/></th>
                      </tr>
                    ) : QRCodeBusinessData.length ? (
                      QRCodeBusinessData.map((QRData,index)=>(
                          <tr key={index}>
                            <td>
                              <img src={QRData.qrCode} width="100" height="100"/>
                            </td>
                            <td className="text-center">{QRData.businessName}</td>
                            <td className="text-center">{QRData.businessOwner}</td>
                            <td className="col">{QRData.Address}</td>
                            <td className="text-center">
                              <img src={QRData.businessLogoUrl} width="70" height="70"/>
                            </td>
                            <td className="text-center">{QRData.mobileNumber}</td>
                            <td className="text-center  ">
                               <div className="form-switch">
                                  <input className="form-check-input custom-control-input" type="checkbox" role="switch"  disabled={!QRData.isPublish}
                                   checked={QRData.isActive} onChange={()=>handleStatus(QRData)}/>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="form-switch text-center">
                                <input className="form-check-input" type="checkbox" role="switch" disabled={QRData.isPublish}
                                checked={QRData.isPublish} onClick={()=>handlePublish(QRData.id)}/>
                              </div>
                            </td>
                           <td className="text-center">
                              <div className="btn-group">
                                  <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                  </button>

                                  <ul className="dropdown-menu py-0">
                                  {QRData.isPublish ?
                                      <div className="">
                                          <li className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${QRData.id}`) } >
                                              <i className="fa-solid fa-eye"></i> View
                                          </li>
                                      </div>
                                        :
                                      <div className="">
                                          <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${QRData.id}`) } >
                                            <i className="fa-solid fa-pen-to-square"></i> Edit
                                          </li>
                                      </div>
                                    }

                                  </ul>
                            </div>
                          </td>
                          </tr>
                      ))
                    ) : (
                      <>
                    <tr className="my-5 text-center">
                      <td colSpan="8">
                        <h4 className="text-muted">No Data Available</h4>
                      </td>
                    </tr>
                  </>
                    )
                  }                                            
                </tbody>
              </table>
            </div>
          </div>
          {qrcodeModal.visibility ? (
            <AddQrcodeModal
              type={qrcodeModal.type}
              id={qrcodeModal.id}
              qrcodeModal={qrcodeModal.visibility}
              setQrcodeModal={setQrcodeModal}
            />
          ) : (
            ""
          )}

        </div>
        </>
      );
}

export default QrCode