import axios from "axios";

const useAxios = () => {
  const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

  const axiosCreate = axios.create({
    baseURL: BASE_URL,
  })
  
  return (
    axiosCreate
  )
}

export default useAxios